import React from 'react';
import './mustPopupHeader.scss';
import { ReactComponent as ShturmanSvg } from '../../../svg/popup-shturman.svg';
import { ReactComponent as FollowSvg } from '../../../svg/follow.svg';


export default class MustPopupHeader extends React.Component {

    render() {
        return (
            <div className='must-popup-header'>
                <ShturmanSvg className='must-popup-header__shturmanSvg' />
                <FollowSvg className='must-popup-header__followSvg' />

                <div className='must-popup-header__text-container'>
                    <div className='must-popup-header__text'>Привет, я Штурман,<br />твой электронный помощник.</div>
                    <div className='must-popup-header__text-with-lines'>
                        <div className='must-popup-header__white-line'></div>
                        <div className='must-popup-header__text must-popup-header__text_strong'>{ this.props.children }</div>
                        <div className='must-popup-header__white-line'></div>
                    </div>
                </div>
            </div>
        );
    }
}