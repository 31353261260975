import 'whatwg-fetch'
import {getToken, isLoggedIn, isTokenExpired, removeToken} from "../modules/auth";

const api = async (path, method = 'GET', body, additionalHeaders = {}) => {
	// we don't need to send request if token is expired
	if (isTokenExpired()) {
		removeToken()
	}

	// merge headers
	let headers = {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		...additionalHeaders
	}

	if (isLoggedIn()) {
		headers['Authorization'] = `Bearer ${getToken()}`
	}

	const bodyFormat = body ? {body: JSON.stringify(body)} : {};

	let response = await window.fetch(`https://api.must.io/api${ path }`, {
		method,
		headers,
		...bodyFormat,
	})
	if (response.status === 401 || response.status === 403) {
		removeToken()
		return false
	} else {
		return response
	}
};

export default api;
