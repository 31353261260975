import React, { Component } from 'react';
import cn from 'classnames';

import { MAIN_MENU } from 'store/app/constants';
import IconSprite from 'components/icon-sprite';
import UserBar from 'components/user-bar';

import './header.scss';

class Header extends Component {
  state = {
    isShowMobileMenu: false,
    isShowShadow: false,
  };

  componentDidMount() {
    if (window.innerWidth <= 768) {
      document.addEventListener('scroll', this.handleScroll);
    }
  };

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      isShowShadow: window.scrollY > 20,
    });
  };

  toggleMobileMenu = (e) => {
    e.preventDefault();

    this.setState((state) => ({
      isShowMobileMenu: !state.isShowMobileMenu,
    }));
  };

  render() {
    const {
      isShowMobileMenu,
      isShowShadow,
    } = this.state;

    return (
      <header className="header">
        <div className="header__inner">
          <div className={cn(
            'header__top',
            { 'header__top_shadow': isShowShadow },
          )}>
            <div className="header__service">
              <div className="header__logo">
                <IconSprite
                  name="logo"
                  className="header__logo-svg"
                />
              </div>
              <div className="header__service-name">
                <IconSprite
                  name="logo-text"
                />
              </div>

              {
                isShowMobileMenu
                  ? (
                    <div className="header__mmenu">
                      <div className="header__mmenu-nav">
                        <ul className="header__nav-list header__nav-list_mobile">
                          {
                            MAIN_MENU.map((menuItem, index) => (
                              <li
                                key={`main-mobile-menu-item-${ index }`}
                                className={cn(
                                  'header__nav-item',
                                  {'header__nav-item_active': menuItem.url === '/'},
                                )}
                              >
                                <span className="header__nav-icon">
                                  <IconSprite name={ menuItem.icon }/>
                                </span>
                                <a
                                  href={ menuItem.url }
                                  target={ menuItem.isExternal ? '_blank' : null }
                                  className="header__nav-link"
                                >{ menuItem.label }</a>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                      <UserBar />
                    </div>
                  )
                  : null
              }
            </div>

            <button
              className={cn(
                'header__burger',
                { 'header__burger_active': isShowMobileMenu }
              )}
              type="button"
              onClick={ this.toggleMobileMenu }
            >
              <span className="header__burger-line" />
            </button>
          </div>

          <nav className="header__nav">
            <ul className="header__nav-list">
              {
                MAIN_MENU.map((menuItem, index) => (
                  <li
                    key={`main-menu-item-${ index }`}
                    className={cn(
                      'header__nav-item',
                      {'header__nav-item_active': menuItem.url === '/'},
                    )}
                  >
                    <span className="header__nav-icon">
                      <IconSprite name={ menuItem.icon }/>
                    </span>
                    <a
                      href={ menuItem.url }
                      target={ menuItem.isExternal ? '_blank' : null }
                      className="header__nav-link"
                    >{ menuItem.label }</a>
                  </li>
                ))
              }
            </ul>
          </nav>
        </div>
      </header>
    )
  }
}

export default Header;