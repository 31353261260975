import React from 'react';
import './carousel-item.scss';
import {element, string} from 'prop-types'
import {Text, TextColor, TextFont, TextSize} from '../text/Text';

export class CarouselItem extends React.Component {

  static propTypes = {
    svg: element,
    text: string,
    subText: string
  };

  render() {
    const {svg, text, subText} = this.props;
    return (
      <div className="carousel-item">
        <div className="carousel-item__row">
          {svg}
        <Text size={TextSize.S_16} color={TextColor.ABSOLUTE_BLACK} className="carousel-item__text">{text}</Text>
        </div>
        <Text size={TextSize.S_14} color={TextColor.DARK_GRAY} font={TextFont.UBUNTU}>{subText}</Text>
      </div>
    );
  }

}
