import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import Cookies from 'js-cookie';

import api from 'api';
import AppContext from 'store/context';
import Layout from 'components/layout';
import InfoCard, { FakeCard } from 'components/info-card';
import FlatBlock from 'components/flat-block';
import {isLoggedIn} from "../../modules/auth";

class HistoryPage extends Component {
  state = {
    isToken: isLoggedIn(),
  };

  componentDidMount() {
    this.checkCards();
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('is-fixed');
  }

  checkCards = () => {
    if (!this.context.historyCards && this.state.isToken) {
      this.getCards();
    }
  };

  getCards = async() => {
    const response = await api('/profile/history');

    if (response.status === 200) {
      const historyCards = await response.json();

      this.context.setHistoryCards(historyCards.history);
    }
  };

  render() {
	  let groupedCards = {};
	  const { historyCards = null } = this.context;
	  const moreLinkText = historyCards && historyCards.length
      ? 'Проверить еще один номер'
      : 'Узнать вероятность ДТП';

    if (historyCards && historyCards.length) {
      groupedCards = groupBy(historyCards, 'plates');
    }

    return (
      <Layout>
        <div className="page">
          <div className="wrapper">
            {
              Object.keys(groupedCards).length
                ? map(groupedCards, (groupedCard) => (
                    <FlatBlock
                      key={ groupedCard[0].id }
                      type='center'
                    >
                      <InfoCard cards={ groupedCard } />
                    </FlatBlock>
                  ))
                : (
              	    this.context.isWaiting
	                    ? <FlatBlock type='center'>
			                    <FakeCard />
			                  </FlatBlock>
	                    : <FlatBlock type='center'>
			                    <h2 style={{
			                    	textAlign: 'center',
			                    	color: '#9e9e9e',
                            fontWeight: 400,
                            maxWidth: 450,
                            margin: '0 auto',
			                    }}>Сделай первый расчет вероятности ДТП, чтобы появилась история запросов</h2>
			                  </FlatBlock>
	                )
            }


            {
              this.state.isToken
                ? (
                    !this.context.isWaiting
                        ? <FlatBlock blockType='center'>
                            <Link
                              to='/'
                              className='button button_width_auto button_type_center'
                            >{ moreLinkText }</Link>
                          </FlatBlock>
                        : null
                  )
                : <Redirect to="/" />
            }
          </div>
        </div>
      </Layout>
    );
  }
}

HistoryPage.contextType = AppContext;

export default HistoryPage;
