import React from 'react';
import './email.scss';
import {bool, func, string} from 'prop-types';
import size from 'lodash/size';
import classNames from 'classnames';
import {ErrorBubble} from '../error-bubble/ErrorBubble';
import {isEmailValid} from '../../common/validation/isEmailValid';

export class Email extends React.Component {

  static propTypes = {
    email: string,
    onEmailChange: func,
    onEnter: func,
    disabled: bool
  };

  state = {
    validationShown: false
  };

  static defaultProps = {
    onEmailChange() {
    },
    onEnter() {

    }
  };

  handleEmailChange = (e) => {
    const value = e.target.value;
    const {onEmailChange} = this.props;
    onEmailChange(value);
    if (isEmailValid(value)) {
      this.setState({validationShown: false})
    }
  };

  handleKeyPress = (e) => {
    const {onEnter} = this.props;
    if (e.key === 'Enter') {
      if (this.hasError()) {
        this.setState({validationShown: true})
      } else {
        onEnter();
      }
    }
  };

  hasError() {
    const {email} = this.props;
    return size(email) && !isEmailValid(email);
  }

  handleBlur = () => {
    if (this.hasError()) {
      this.setState({validationShown: true})
    } else {
      if (this.props.onBlur) {
        this.props.onBlur();
      }
    }
  };

  render() {
    const {email, disabled, inputRef} = this.props;
    const {validationShown} = this.state;
    return (
      <div className={classNames('email', validationShown ? 'email_error' : '')}>
        <ErrorBubble shown={validationShown}
                     text="В email содержится ошибка"
                     className="email__error-bubble"/>
        <input value={email}
               ref={inputRef}
               type="email"
               name="email"
               onBlur={this.handleBlur}
               disabled={disabled}
               onChange={this.handleEmailChange}
               onKeyPress={this.handleKeyPress}
               className="email__input"
               inputMode="email"
               placeholder="Введи свой email..."/>
      </div>
    );
  }

}
