/*
 * url для ссылок содержат хэш showform
 * это id пунка меню тильды на сайте https://mustins.ru/
 * т.к. все сделано попапами, единственный вариант
 */
const mainDomain = 'https://mustins.ru';
export const MAIN_MENU = [
  {
    label: 'Вероятность ДТП',
    icon: 'score',
    url: '/',
  },
  {
    label: 'еОСАГО',
    icon: 'shield',
    isExternal: true,
    url: 'http://osago.mustins.ru/',
  },
  {
    label: 'ГОП',
    icon: 'truck',
    isExternal: true,
    url: `${ mainDomain }#showform-1561228871099`,
  },
  {
    label: 'НС',
    icon: 'umbrella',
    isExternal: true,
    url: `${ mainDomain }#showform-1561228836010`,
  },
  {
    label: 'Грузы',
    icon: 'pack',
    isExternal: true,
    url: `${ mainDomain }#showform-1561228746803`,
  },
  {
    label: 'Турнир',
    icon: 'cup',
    isExternal: true,
    url: 'https://mustwin.ru/',
  },
  {
    label: 'FAQ',
    icon: 'faq',
    isExternal: true,
    url: 'https://mustins.ru/faq',
  },
];


export const Ranges = [
  {
    caption: 'отлично',
    min: 0,
    max: 2.18,
    minDeg: 90,
    maxDeg: 51,
    color: '#27B4B8',
    className: 'legend-green',
  },
  {
    caption: 'нормально',
    min: 2.18,
    max: 4.3,
    minDeg: 42,
    maxDeg: 30,
    color: '#4C86EE',
    className: 'legend-blue',
  },
  {
    caption: 'терпимо',
    min: 4.3,
    max: 7.37,
    minDeg: 23,
    maxDeg: 13,
    color: '#E6C104',
    className: 'legend-yellow',
  },
  {
    caption: 'опасно',
    min: 7.37,
    max: 12.47,
    minDeg: 5,
    maxDeg: -4,
    color: '#DB8A3D',
    className: 'legend-orringe',
  },
  {
    caption: 'угроза ДТП',
    min: 12.47,
    max: 50,
    minDeg: -14,
    maxDeg: -90,
    color: '#D76064',
    className: 'legend-red',
  },
  ///MAX
  {
    caption: 'угроза ДТП',
    min: 50,
    max: 100,
    minDeg: -90,
    maxDeg: -90,
    color: '#D76064',
    className: 'legend-red',
  },
  {
    caption: 'нет данных',
    min: 0,
    max: 0,
    minDeg: 0,
    maxDeg: 0,
    color: '#fffff',
    className: 'score-graph__text-white',
  }
];