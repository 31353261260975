import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import Cookies from 'js-cookie';

import api from 'api';
import errorCodes from 'utils/errorCodes';
import FlatBlock from '../../components/flat-block';
import {TextColor, TextSize, Text} from '../../components/text/Text';
import {PhoneNumber} from '../../components/phone-number/PhoneNumber';
import {Email} from '../../components/email/Email';
import AppContext from '../../store/context';
import Layout from '../../components/layout';
import { Button } from '../../components/button/Button';
import ChangePhone from '../../components/change-phone';
import { ReactComponent as SuccessLogo } from '../../svg/success.svg';
import MustPopup from "../../components/mustPopup";
import {getUserInfo, initOtp} from "../../components/main-panel/MainPanelModel";

import './profile.scss'
import {isLoggedIn} from "../../modules/auth";

let waiter;
let changeWaiter;

class ProfilePage extends Component {
	state = {
		firstName: (this.context.user && this.context.user.firstName) || '',
		lastName: (this.context.user && this.context.user.lastName) || '',
		phoneNumber: (this.context.user && this.context.user.phone && this.context.user.phone.substr(2)) || '',
		email: (this.context.user && this.context.user.email) || '',
		user: this.context.user,
		isFirstRender: true,
		isNameChange: false,
		isLastNameChange: false,
		isEmailChange: false,
		isShowPhonePopup: false,
		isShowEmailPopup: false,
		isWaitSms: false,
		otpState: '',
		isToken: isLoggedIn(),
		error: null,
	};

	setNewData = async ({ firstName, lastName, email }, isFromLastName = false, isFromEmail = false) => {
		let dataToRefresh = {};

		if (firstName) {
			dataToRefresh = {
				firstName,
			};
		}

		if (lastName) {
			dataToRefresh = {
				...dataToRefresh,
				lastName,
			}
		}

		if (email) {
			dataToRefresh = {
				...dataToRefresh,
				email,
			}
		}

		const response = await api('/profile', 'POST', {
      ...dataToRefresh,
    });

		if (response.status === 200) {
			const user = await response.json();

			clearTimeout(changeWaiter);
			changeWaiter = this.setState({
				isNameChange: !isFromLastName && !isFromEmail,
				isLastNameChange: isFromLastName && !isFromEmail,
				isEmailChange: isFromEmail,
			}, () => {
				changeWaiter = setTimeout(() => {
					this.setState({
						isNameChange: false,
						isLastNameChange: false,
						isEmailChange: false,
					})
				}, 2000);
			});

			this.context.setData({user});
		}
	};

	handleNameChange = (e) => {
		this.setState({
			firstName: e.target.value,
		}, () => {
			clearTimeout(waiter);
			waiter = setTimeout(() => {
				this.setNewData({
					firstName: this.state.firstName,
				});
			}, 500);
		});
	};

	handleSurNameChange = (e) => {
		this.setState({
			lastName: e.target.value,
		}, () => {
			clearTimeout(waiter);
			waiter = setTimeout(() => {
				this.setNewData({
					firstName: this.state.firstName,
					lastName: this.state.lastName,
				}, true);
			}, 500);
		});
	};

	handleNumberChange = (phoneNumber) => {
		this.setState({
			phoneNumber,
		});
	};

	handleNumberEnter = () => {
		if (this.context.user) {
			if (
					this.context.user.phone !== `+7${ this.state.phoneNumber }`
					&& this.state.phoneNumber.length === 10
			) {
				this.setState({
					isShowPhonePopup: true,
				});
			} else {
				this.setState({
					phoneNumber: this.context.user.phone.substr(2),
				})
			}
		}
	};

	onPhonePopupClose = () => {
		this.setState({
			isShowPhonePopup: false,
			isWaitSms: false,
		});
	};

	sendSMS = async () => {
		const response = await initOtp('+7' + this.state.phoneNumber);
		const data = await response.json();

		if (data) {
			if (data.otpState) {
				this.setState({
					otpState: data.otpState,
					isWaitSms: true,
				});
			}
			else {
				const error = errorCodes(data.errorCode);

				this.setState({
					error,
				});
			}
		}
	};

	handleEmailChange = (email) => {
    this.setState({
	    email,
    });
  };

	handleEmailEnter = () => {
		if (this.context.user && this.context.user.email !== this.state.email) {
			this.setState({
				isShowEmailPopup: true,
			});
		}
	};

	onEmailChange = () => {
		this.setNewData({
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			email: this.state.email,
		}, false, true);

		this.setState({
			isShowEmailPopup: false,
		});
	};

	onEmailPopupClose = () => {
		this.setState({
			isShowEmailPopup: false,
		});
	};

	componentDidUpdate(prevProps) {
		if (
			this.state.isFirstRender
			&& this.context.user
			&& !isEqual(this.state.user, this.context.user)
		) {
			const {
				firstName = '',
				lastName = '',
				email = '',
				phone = '',
			} = this.context.user;

			this.setState({
				firstName,
				lastName,
				email,
				phoneNumber: phone && phone.length > 2 ? phone.substr(2) : '',
				isFirstRender: false,
			})
		}
	}

	setNewUser = async () => {
		if (isLoggedIn()) {
			const response = await getUserInfo();

			if (response.status === 200) {
				const user = await response.json();

				this.context.setData({user})
			}
		}
	};

	onPhoneSuccess = () => {
		this.setNewUser();

		this.setState({
			isShowPhonePopup: false,
			isWaitSms: false,
		});
	};

	render() {
		const {
			firstName,
			lastName,
			phoneNumber,
			email,
			isWaitSms,
			otpState,
			error,
		} = this.state;

		return (
			<Layout>
				<div className="page">
					<div className="wrapper">
						{
							this.state.isToken
								? null
								: <Redirect to="/" />
						}
						{
							!this.context.user
								? null
								: (
									<FlatBlock>
										<div className="page-profile__main">
											<div className="page-profile-column">
												<div className="page-profile-column__item">
													<Text
														color={TextColor.ABSOLUTE_BLACK}
														size={TextSize.S_14}
														uppercase={true}
														className="page-profile-column__title"
													>Имя</Text>

													<div className="page-profile__input">
														<input
															value={firstName}
															onChange={this.handleNameChange}
															className="name__input"
															placeholder="Введите свое имя..."
															name="fname"
														/>
														<SuccessLogo
															className={cn(
																'page-profile__input-svg',
																{ 'page-profile__input-svg_active': this.state.isNameChange }
															)}
														/>
													</div>
												</div>
												<div className="page-profile-column__item">
														<Text
															color={TextColor.ABSOLUTE_BLACK}
															size={TextSize.S_14}
															uppercase={true}
															className="page-profile-column__title"
														>Фамилия</Text>
														<div className="page-profile__subtext">Необходимо для верификации</div>

													<div className="page-profile__input">
														<input
															value={lastName}
															onChange={this.handleSurNameChange}
															className="name__input"
															placeholder="Введи свою фамилию..."
															name="lname"
														/>

														<SuccessLogo
															className={cn(
																'page-profile__input-svg',
																{ 'page-profile__input-svg_active': this.state.isLastNameChange }
															)}
														/>
													</div>
												</div>
											</div>
											<div className="page-profile-column">
												<div className="page-profile-column__item">
													<Text
														color={TextColor.ABSOLUTE_BLACK}
														size={TextSize.S_14}
														uppercase={true}
														className="page-profile-column__title"
													>Телефон</Text>

													<PhoneNumber
														onNumberChange={this.handleNumberChange}
														onEnter={ this.handleNumberEnter }
														number={ phoneNumber }
														onBlur={ this.handleNumberEnter }
													/>

													{
														this.state.isShowPhonePopup
															? (
																<MustPopup
																	header={(<span>вы обновляете телефон,<br />мы отправим СМС с кодом<br />на номер +7({phoneNumber.substr(0, 3)})***-**-{phoneNumber.substr(8, 2)}</span>)}
																	onClose={ this.onPhonePopupClose }
																>
																	{
																		isWaitSms
																			? <ChangePhone
																					otpState={ otpState }
																					phoneNumber={ phoneNumber }
																					onSuccess={ this.onPhoneSuccess }
																				/>
																			: <>
																					<div className="page-profile__btn">
																						<Button onClick={ this.sendSMS }>Да, я хочу изменить телефон</Button>
																					</div>
																					{
																						error
																							? <div className="popup-inner-view__item">
																									<Text color={TextColor.RED}>{ error }</Text>
																								</div>
																								: null
																					}
																				</>
																	}
																</MustPopup>
															)
															: null
													}
												</div>

												<div className="page-profile-column__item">
													<div className="page-profile-column__title">
														<Text
															color={TextColor.ABSOLUTE_BLACK}
															size={TextSize.S_14}
															uppercase={true}
															className="page-profile-column__title"
														>Email</Text>
														<div className="page-profile__subtext">На эту почту приходят детализированные отчеты</div>
													</div>
													<Email
														email={email}
														onEmailChange={this.handleEmailChange}
														onEnter={ this.handleEmailEnter }
														onBlur={ this.handleEmailEnter }
													/>

													{
														this.state.isShowEmailPopup
															? (
																<MustPopup
																	header={(<span>мы отправим письмо<br />с подтверждением<br />на новый email</span>)}
																	onClose={ this.onEmailPopupClose }
																>
																	<div className="page-profile__btn">
																		<Button onClick={ this.onEmailChange }>Да, я хочу изменить email</Button>
																	</div>
																</MustPopup>
															)
															: null
													}
												</div>
											</div>
										</div>
									</FlatBlock>
								)
						}
					</div>
				</div>
			</Layout>
		)
	}
}

ProfilePage.contextType = AppContext;

export default ProfilePage;
