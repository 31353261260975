import React, {PureComponent} from 'react';
import {Text, TextColor, TextFont, TextSize} from "../../../text/Text";
import {PhoneNumber} from "../../../phone-number/PhoneNumber";
import cn from "classnames";
import {Button} from "../../../button/Button";
import {Checkbox} from "../../../checkbox/Checkbox";
import {SmsCode} from "../../../sms-code/SmsCode";
import {Countdown} from "../../../countdown/Countdown";
import size from "lodash/size";
import {apply, initOtp, login} from "../../../main-panel/MainPanelModel";
import Cookies from 'js-cookie';
import {saveToken} from "../../../../modules/auth";

class RegistrationSMS extends PureComponent {
	state = {
		phoneNumber: '',
		name: null,
		checked: false,
		smsCodeAwait: false,
		countdown: 1,
		smsCode: '',
		error: null,
		isShowPopup: false,
	};

	onNumberChange = (phoneNumber) => {
		this.setState({phoneNumber: phoneNumber});
	};

	onValidation = async () => {
		const {phoneNumber, checked, otpState, name} = this.state;
		if (!checked || !this.isDataValid()) {
			return;
		}

		this.setState({smsCodeAwait: true})
	};

	isDataValid = () => {
		const {phoneNumber, name, checked} = this.state;
		return phoneNumber.length === 10 && !!name && checked;
	};

	getError = () => {
		const {errorCode} = this.state;
		switch (errorCode) {
			case (1001):
				return 'Несуществующий телефонный номер';
			case (1002):
				return 'Неправильный телефонный номер';
			default:
				return 'Что то пошло не так, попробуйте еще раз';
		}
	};

	onCountdownEnd = () => {
		this.setState({countdown: 0});
	};

	sendData = async (smsCode) => {
    const {
    	otpState,
	    phoneNumber,
	    onSuccess,
    } = this.props;
    const response = await login('+7' + phoneNumber, otpState, smsCode);

    if (response.status === 400) {
      const data = await response.json();

      if (data && data.errorCode) {
        if (data.errorCode == 1005) {
          this.setState({error: 'Введен неверный код из смс'});
        } else {
          this.setState({error: 'Сервер недоступен'});
        }
      }
    } else {
      const data = await response.json();

      if (data.token) {
	  	saveToken(data.token)

        onSuccess();
      }
    }
  };

	onSmsCodeChange = (smsCode) => {
		this.setState({smsCode: smsCode});

		if (size(smsCode) === 4) {
			this.sendData(smsCode);
		}
	};

	updateSmsCode = async () => {
		const {
			otpState,
			phoneNumber,
			onUpdateSmsCode,
		} = this.props;

		this.setState({
			smsCode: '',
		});
	};

	checkSmsCode = (e) => {
		e.preventDefault();
	};

	render() {
		const {
			smsCode,
			countdown,
			error,
		} = this.state;

		return (
			<>
				<div className="popup-inner-view__item">
					<div className="popup-inner-view__title">
						<Text
							color={TextColor.GRAY}
							size={TextSize.S_14}
							uppercase={true}
							className="popup-inner-view__digit"
						>03</Text>
						<Text
							color={TextColor.ABSOLUTE_BLACK}
							size={TextSize.S_14}
							uppercase={true}
						>Код из СМС</Text>
					</div>

					<SmsCode
						onSmsCodeChange={this.onSmsCodeChange}
						onEnter={this.onClick}
						smsCode={smsCode}
					/>

					{
						error
							? <div className="popup-inner-view__item">
								<Text color={TextColor.RED}>{error}</Text>
							</div>
							: null
					}

					{
						countdown !== 0
							? <div className="popup-inner-view__item">
								<Text
									size={TextSize.S_12}
									font={TextFont.UBUNTU}
									className="popup-inner-view__countdown-text"
								>Запросить код повторном можно через <Countdown onTimeOver={this.onCountdownEnd}/> сек </Text>
							</div>
							: null
					}

					{
						countdown === 0
							? <div className="popup-inner-view__item">
								<Button
									onClick={ this.updateSmsCode }
									className='popup-inner-view__no-border-button'
								>Запросить новый код</Button>
							</div>
							: null
					}
				</div>

				<div className={cn('popup-inner-view__item', !this.isDataValid() ? 'popup__disabled' : '')}>
					<Button onClick={this.checkSmsCode}>Подтвердить</Button>
				</div>
			</>
		)
	}
}

export default RegistrationSMS;
