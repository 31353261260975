import React, { useEffect } from 'react';
import Header from 'components/header';
import UserBar from 'components/user-bar';

const Layout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <UserBar isMobile={ true } />

      { children }
    </>
  );
};

export default Layout;