import React, { PureComponent } from 'react';
import {Text, TextColor, TextFont, TextSize} from "../../../text/Text";
import {PhoneNumber} from "../../../phone-number/PhoneNumber";
import cn from "classnames";
import {Button} from "../../../button/Button";
import {Checkbox} from "../../../checkbox/Checkbox";
import {initOtp} from "../../../main-panel/MainPanelModel";

class RegistrationMain extends PureComponent {
	state = {
		phoneNumber: '',
		name: null,
		errorCode: null,
		checked: true,

		smsCodeAwait: false,
		countdown: 1,
		smsCode: '',
		error: null,
		nameError: false,
		phoneNumberError: false,
	};

	onNumberChange = (phoneNumber) => {
		this.setState({
			phoneNumber: phoneNumber,
			phoneNumberError: this.state.phoneNumberError && phoneNumber.length < 10,
		});
	};

	handleNameChange = (e) => {
		this.setState({
			name: e.target.value,
			nameError: this.state.nameError && (!e.target.value || e.target.value.length < 2),
		});
	};

	handleCheckedChange = () => {
		const {checked} = this.state;
		this.setState({
			checked: !checked
		});
	};

	onValidation = async () => {
		const {
			phoneNumber,
			checked,
			otpState,
			name,
		} = this.state;

		if (!checked || !this.isDataValid()) {
			return;
		}

		const response = await initOtp('+7' + phoneNumber);

		if (response.status === 200) {
			const data = await response.json();

			if (data) {
				if (data.otpState) {
					this.setState({
						otpState: data.otpState,
					});

					this.props.onWaitCode(data.otpState, phoneNumber, name);
				}
				else {
					this.setState({
						errorCode: data.errorCode,
					});
				}
			}
		} else {
			this.setState({
				errorCode: 1,
			});
		}
	};

	isDataValid = () => {
		const {phoneNumber, name, checked} = this.state;
		return phoneNumber.length === 10 && !!name && checked;
	};

	getError = () => {
		const {errorCode} = this.state;
		switch (errorCode) {
			case (1001):
				return 'Несуществующий телефонный номер';
			case (1002):
				return 'Неправильный телефонный номер';
			default:
				return 'Что то пошло не так, попробуйте еще раз';
		}
	};

	highlightFields = () => {
		let hightlight = {};

		if (!this.state.name || this.state.name.length < 2) {
			hightlight.nameError = true;
		}

		if (this.state.phoneNumber.length < 10) {
			hightlight.phoneNumberError = true;
		}

		this.setState((state) => ({
			...state,
			...hightlight,
		}));
	};

	render() {
		const {
			phoneNumber,
			name,
			checked,
			errorCode,
		} = this.state;

		return (
			<>
				<div className={cn(
						'must-popup-view__item',
						{ 'must-popup-view__item_error': this.state.nameError },
					)}>
					<div className="must-popup-view__title">
						<Text color={TextColor.GRAY} size={TextSize.S_14} uppercase={true} className="must-popup-view__digit">01</Text>
						<Text color={TextColor.ABSOLUTE_BLACK} size={TextSize.S_14} uppercase={true}>Как обращаться</Text>
					</div>

					<input value={name}
						onChange={this.handleNameChange}
						className="name__input"
						placeholder="Имя..."
					/>
				</div>

				<div className={cn(
							'must-popup-view__item',
							{ 'must-popup-view__item_error': this.state.phoneNumberError },
						)}>
					<div className="must-popup-view__title">
						<Text color={TextColor.GRAY} size={TextSize.S_14} uppercase={true} className="must-popup-view__digit">02</Text>
						<Text color={TextColor.ABSOLUTE_BLACK} size={TextSize.S_14} uppercase={true}>Введи свой контактный телефон</Text>
					</div>

					<PhoneNumber
						onNumberChange={this.onNumberChange}
						onEnter={this.onValidation}
						number={phoneNumber}
					/>
				</div>

				<div className="popup-inner-view__item tc">
					<a href="#" className={'button-link'} onClick={(e) => {
						e.preventDefault()
						this.props.onClose()
						document.getElementById('loginBtn').click()
					}}>
						У меня уже есть личный кабинет
					</a>
				</div>

				{
					errorCode
					? <div className="popup-inner-view__item">
							<Text color={TextColor.RED} size={TextSize.S_14} font={TextFont.UBUNTU}>{this.getError()}</Text>
						</div>
					: null
				}

				<div className={cn(
					'must-popup-view__item',
					!this.isDataValid() ? 'popup__disabled' : ''
				)}
				>
					<div onMouseEnter={ this.highlightFields }>
						<Button
							onClick={ this.onValidation }
							disabled={ !this.isDataValid() }
						>Создать аккаунт</Button>
					</div>
				</div>

				<Checkbox name="must-popup__agreement"
					className="must-popup-view__item"
					checked={checked}
					onChange={this.handleCheckedChange}
					disabled={false}
					label='Я согласен на обработку моих персональных данных в целях расчета'
				/>
			</>
		)
	}
}

export default RegistrationMain;