import React from 'react';
import './popup-inner-view.scss';
import { TextColor, TextSize, TextFont, Text } from '../../text/Text';
import { Checkbox } from '../../checkbox/Checkbox';
import { Button } from '../../button/Button';
import { PhoneNumber } from '../../phone-number/PhoneNumber';
import { func } from 'prop-types';
import { initOtp } from '../../main-panel/MainPanelModel';
import classNames from 'classnames';

export class InitialInnerView extends React.Component {

    state = {
        phoneNumber: '',
        errorCode: null,
        otpState: null,
        name: null,
        checked: true
    }

    onNumberChange = (phoneNumber) => {
        this.setState({ phoneNumber: phoneNumber });
    }

    handleNameChange = (e) => {
        this.setState({ name: e.target.value });
    }

    static propTypes = {
        onClick: func
    }

    handleCheckedChange = () => {
        const { checked } = this.state;
        this.setState({ 
            checked: !checked
        });
    };
    
    onValidation = async () => {
        const { phoneNumber, checked, otpState, name } = this.state;
        const {onClick} = this.props;
        if (!checked || !this.isDataValid()) {
            return;
        }

        const response = await initOtp('+7' + phoneNumber);
        const data = await response.json();

        if (data) {
            if (data.otpState) {
                this.setState({
                    otpState: data.otpState,
                });
                onClick(data.otpState, phoneNumber, name);
            }
            else {
                this.setState({
                    errorCode: data.errorCode,
                });
            }
        }
    }

    getError = () => {
        const { errorCode } = this.state;
        switch (errorCode) {
            case (1001):
                return 'Несуществующий телефонный номер';
            case (1002):
                return 'Неправильный телефонный номер';
            default:
                return 'Что то пошло не так, попробуйте еще раз';
        }
    }

    isDataValid = () =>{
        const { phoneNumber, name, checked } = this.state;
        return phoneNumber.length === 10 && !!name && checked;
    }

    render() {
        const { phoneNumber, name, errorCode, checked } = this.state;
        return (
            <div className="popup-inner-view">
                <div className="popup-inner-view__item">
                    <div className="popup-inner-view__title">
                        <Text color={TextColor.GRAY} size={TextSize.S_14} uppercase={true} className="popup-inner-view__digit">01</Text>
                        <Text color={TextColor.ABSOLUTE_BLACK} size={TextSize.S_14} uppercase={true}>Как обращаться</Text>
                    </div>

                    <input value={name}
                        onChange={this.handleNameChange}
                        className="name__input"
                        placeholder="Имя..."
                        name="fname"
                    />

                </div>

                <div className="popup-inner-view__item">
                    <div className="popup-inner-view__title">
                        <Text color={TextColor.GRAY} size={TextSize.S_14} uppercase={true} className="popup-inner-view__digit">02</Text>
                        <Text color={TextColor.ABSOLUTE_BLACK} size={TextSize.S_14} uppercase={true}>Введи свой контактный телефон</Text>
                    </div>

                    <PhoneNumber
                        onNumberChange={this.onNumberChange}
                        onEnter={this.onValidation}
                        number={phoneNumber}
                    />

                </div>

                {errorCode && <div className="popup-inner-view__item">
                    <Text color={TextColor.RED} size={TextSize.S_14} font={TextFont.UBUNTU}>{this.getError()}</Text>
                </div>}

                <div className={classNames('popup-inner-view__item', !this.isDataValid() ? 'popup__disabled' : '')}>
                    <Button onClick={this.onValidation} disabled={!this.isDataValid()} >Получить ОСАГО</Button>
                </div>

                <Checkbox name="popup__agreement"
                    className="popup-inner-view__item"
                    checked={checked}
                    disabled={false}
                    onChange={this.handleCheckedChange}
                    label='Я согласен на обработку моих персональных данных в целях расчета' />
            </div>
        );
    }
}