import React from 'react';
import './auto-form.scss';
import {bool, func, string} from 'prop-types';
import {ReactComponent as ManSvg} from '../../svg/man.svg';
import {CarNumber} from '../car-number/CarNumber';
import {Text, TextColor, TextSize} from '../text/Text';
import {Email} from '../email/Email';
import {Button} from '../button/Button';
import {Checkbox} from '../checkbox/Checkbox';
import size from 'lodash/size';
import classNames from 'classnames';
import {isCarNumberValid} from '../../common/validation/isCarNumberValid';
import {isEmailValid} from '../../common/validation/isEmailValid';
import AppContext from '../../store/context';

function shouldFocusOnEmail(number, region, email) {
  return isCarNumberValid(number, region) && size(region) === 3 && !isEmailValid(email);
}

export class AutoForm extends React.Component {

  emailInputRef = React.createRef();

  static propTypes = {
    onFormUpdate: func,
    onButtonClick: func,
    number: string,
    region: string,
    email: string,
    checked: bool,
    onNumberChange: func,
    onRegionChange: func,
    onEmailChange: func,
    onCheckedChange: func,
    disabled: bool
  };

  handleNumberChange = (number) => {
    const {region, onNumberChange, email} = this.props;
    onNumberChange(number);
    if (shouldFocusOnEmail(number, region, email)) {
      setTimeout(() => {
        if (this.emailInputRef && this.emailInputRef.current) {
          this.emailInputRef.current.focus();
        }
      }, 100)

    }
  };

  handleRegionChange = (region) => {
    const {number, onRegionChange, email} = this.props;
    onRegionChange(region);
    if (shouldFocusOnEmail(number, region, email)) {
      setTimeout(() => {
        if (this.emailInputRef && this.emailInputRef.current) {
	        this.emailInputRef.current.focus();
        }
      }, 100)
    }
  };

  handleEmailChange = (email) => {
    const {onEmailChange} = this.props;
    onEmailChange(email);
  };

  isCarNumberValid = () => {
    const {number, region} = this.props;
    return isCarNumberValid(number, region);
  };

  isEmailValid = () => {
    const {email} = this.props;
    return isEmailValid(email);
  };
  onAgreementChange = () => {
    const {onCheckedChange, checked} = this.props;
    onCheckedChange(!checked);
  };

  handleEnter = () => {
    const {checked, onButtonClick} = this.props;
    if (this.isCarNumberValid() && this.isEmailValid() && checked) {
      onButtonClick();
    }
  };

  render() {
    const {onButtonClick, number, region, email, checked, disabled, numberRef} = this.props;
    return (
      <form className="auto-form" noValidate={true}>
        <div className="auto-form__man">
          <ManSvg className="auto-form__man-svg"/>
        </div>
        <div>
          <div className="auto-form__car-number-title">
            <Text color={TextColor.GRAY} size={TextSize.S_14} uppercase={true} className="auto-form__digit">01</Text>
            <Text color={TextColor.ABSOLUTE_BLACK} size={TextSize.S_14} uppercase={true}>Введи гос. номер грузовика</Text>
          </div>
          <CarNumber number={number}
                     numberRef={numberRef}
                     region={region}
                     onNumberChange={this.handleNumberChange}
                     readonly={disabled}
                     onEnterPress={this.handleEnter}
                     onRegionChange={this.handleRegionChange}/>
        </div>

        <AppContext.Consumer>
          {
            (context) => {
              if (context.user && context.user.email) {
                return null;
              }

	            return (
                  <div className={classNames(!this.isCarNumberValid() ? 'auto-form__disabled' : '')}>
                  <div className="auto-form__email-title">
                    <Text color={TextColor.GRAY} size={TextSize.S_14} uppercase={true}
                          className="auto-form__digit">02</Text>
                    <Text color={TextColor.ABSOLUTE_BLACK} size={TextSize.S_14} uppercase={true}>Ваш email для
                      отчета</Text>
                  </div>

                  <Email
                    inputRef={this.emailInputRef}
                    email={email}
                    onEmailChange={this.handleEmailChange}
                    disabled={!this.isCarNumberValid() || disabled}
                    onEnter={this.handleEnter}
                  />
                </div>
              )
            }
          }
        </AppContext.Consumer>

        <div className={classNames((!this.isEmailValid() || !this.isCarNumberValid()) ? 'auto-form__disabled' : '')}>
          <div className="auto-form__email-title">
            <Text color={TextColor.GRAY} size={TextSize.S_14} uppercase={true} className="auto-form__digit">
              <AppContext.Consumer>
                {
                  context => context.user && context.user.email ? '02' : '03'
                }
              </AppContext.Consumer>
            </Text>
            <Text color={TextColor.ABSOLUTE_BLACK} size={TextSize.S_14} uppercase={true}>подтверждение</Text>
          </div>
          <Button className={classNames(this.isEmailValid() && this.isCarNumberValid() && !checked ? 'auto-form__disabled' : '')}
                  disabled={disabled || !this.isEmailValid() || !this.isCarNumberValid() || !checked} onClick={onButtonClick}>Узнать
            вероятность ДТП</Button>
        </div>
        <div className={classNames((!this.isEmailValid() || !this.isCarNumberValid()) ? 'auto-form__disabled' : '')}>
          <Checkbox name="agreement"
                    checked={checked}
                    disabled={disabled || !this.isEmailValid() || !this.isCarNumberValid()}
                    onChange={this.onAgreementChange}
                    label='Я согласен на обработку моих персональных данных в целях расчета'/>
        </div>
      </form>
    );
  }

}
