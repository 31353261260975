import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import AppContext from '../../store/context';
import cn from 'classnames';

import Registration from '../registration';
import ProfileBtn from '../profile-btn';
import ArrowLink from 'components/arrow-link';

import './user-bar.scss';

class UserBar extends Component {
  state = {
    isShowPopup: false,
  };

  showRegistrationPopup = (e) => {
    e.preventDefault();

    this.setState({
      isShowPopup: true,
    });
  };

  onPopupClose = (currentStatus) => {
    if (!currentStatus) {
      this.setState({
        isShowPopup: false,
      });
    }
  };

  render() {
    const { isMobile = false } = this.props;

    return (
      <AppContext.Consumer>
        {
          (context) => (
            <div className="user-bar">
              <div className="wrapper">
                <div className="user-bar__inner">
                  <div className="user-bar__nav">
                    <ul className="user-bar__nav-list">
                      <li className="user-bar__nav-item">
                        <NavLink
                          exact
                          to="/"
                          className="user-bar__nav-link"
                          activeClassName="user-bar__nav-link_active"
                        >Главная</NavLink>
                      </li>
                      <li className="user-bar__nav-item">
                        {
                          context.user
                            ? (
                                <NavLink
                                  exact
                                  to='/history'
                                  className="user-bar__nav-link"
                                  activeClassName="user-bar__nav-link_active"
                                >История запросов вероятности ДТП</NavLink>
                              )
                            : (
		                          <a
                                href="#"
                                className="user-bar__nav-link"
                                onClick={ this.showRegistrationPopup }
                              >История запросов вероятности ДТП</a>
                            )
                        }
                      </li>
                    </ul>
                  </div>
                  {
                    !context.isWaiting
                      ? <div
                          className={cn(
                            'user-bar__user',
                            { 'user-bar__user_mobile': isMobile }
                          )}
                        >
                          <div className="user-bar__user-list">
                            {
                              !context.user
                                ? <div className="user-bar__user-item">
                                    <Registration
                                      toggleShow={ this.state.isShowPopup }
                                      onClose={ this.onPopupClose }
                                    />
                                  </div>
                                : <div className="user-bar__user-item user-bar__user-item_mobile">
                                    <ArrowLink to="/profile">Мой профиль</ArrowLink>
                                  </div>
                            }

                            <div className="user-bar__user-item">
                              <ProfileBtn
                                user={ context.user }
                                setData={ context.setData }
                              />
                            </div>
                          </div>
                        </div>
                      : null
                  }
                </div>
              </div>
            </div>
          )
        }
      </AppContext.Consumer>
    )
  }
}

export default withRouter(UserBar);