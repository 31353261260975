import React from 'react';
import './score-graph.scss';

import {ReactComponent as ScoreGraphSvg} from '../../svg/score-graph.svg';
import {ReactComponent as Pointer} from '../../svg/pointer.svg';
import {ReactComponent as DottedSvg} from '../../svg/dotted.svg';
import {ReactComponent as LogoSvg} from '../../svg/logo.svg';
import {ReactComponent as LogoIngosSvg} from '../../svg/logo-ingos.svg';

import {Text, TextColor, TextSize} from '../text/Text';
import {Legend} from '../legend/Legend';
import classNames from 'classnames';
import {CarNumber} from '../car-number/CarNumber';
import {Speedo} from '../speedo/Speedo';
import {bool, func, number, string} from 'prop-types';

export const Ranges = [
  {
    caption: 'отлично',
    min: 0,
    max: 2.18,
    minDeg: 90,
    maxDeg: 51,
    color: '#27B4B8',
    className: 'score-graph__text-green',
  },
  {
    caption: 'нормально',
    min: 2.18,
    max: 4.3,
    minDeg: 42,
    maxDeg: 30,
    color: '#4C86EE',
    className: 'score-graph__text-blue',
  },
  {
    caption: 'терпимо',
    min: 4.3,
    max: 7.37,
    minDeg: 23,
    maxDeg: 13,
    color: '#E6C104',
    className: 'score-graph__text-yellow',
  },
  {
    caption: 'опасно',
    min: 7.37,
    max: 12.47,
    minDeg: 5,
    maxDeg: -4,
    color: '#DB8A3D',
    className: 'score-graph__text-orringe',
  },
  {
    caption: 'угроза ДТП',
    min: 12.47,
    max: 50,
    minDeg: -14,
    maxDeg: -90,
    color: '#D76064',
    className: 'score-graph__text-red',
  },
  ///MAX
  {
    caption: 'угроза ДТП',
    min: 50,
    max: 100,
    minDeg: -90,
    maxDeg: -90,
    color: '#D76064',
    className: 'score-graph__text-red',
  }
];

export class ScoreGraph extends React.Component {
  static propTypes = {
    loading: bool,
    helpText: string,
    score: number,
    onAnimationFinished: func,
    number: string,
    region: string
  };

  static defaultProps = {
    loading: false,
    helpText: '',
    score: null,
  };

  state = {
    speedoShown: false,
    animationFinished: false
  };

  pointerRef = React.createRef();
  timer = null;
  angle = 180 * Math.PI / 180;

  getRangeByScore = (score) => {
    return Ranges.find((t) => parseFloat(score) > t.min && parseFloat(score) <= t.max);
  }

  setPointerDeg = (deg, time, timingFunction) => {
    this.pointerRef.current.style = `
     -moz-transform: rotate(${deg}deg); 
     -webkit-transform: rotate(${deg}deg);
     -webkit-transform: rotate(${deg}deg); 
     transform:rotate(${deg}deg); 
     transition: ${time}s;
     transition-timing-function: ${timingFunction};
     `;
  };

  getDeg = () => {
    const {score} = this.props;
    let {minDeg, maxDeg, min, max} = this.getRangeByScore(score);
    return minDeg - ((score - min) / (max - min)) * Math.abs(maxDeg - minDeg);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {onAnimationFinished} = this.props;
    if (!prevProps.loading && this.props.loading) {
      let deg = 90;
      this.setPointerDeg(deg, 1, 'cubic-bezier(0, 0, 0.2, 1)');
      setTimeout(() => {
        deg = -90;
        this.setPointerDeg(deg, 1.6, 'cubic-bezier(0, 0, 0.2, 1)');
        const interval = setInterval(() => {
          if (!this.props.loading) {
            clearInterval(interval);
            this.setPointerDeg(0, 0, 'cubic-bezier(0, 0, 0.2, 1)');
            return;
          }
          if (this.props.score && deg === 90) {
            clearInterval(interval);
            this.setState({speedoShown: true});
            setTimeout(() => {
              onAnimationFinished();
              this.setState({animationFinished: true});
            }, 2500);
            const range = this.getRangeByScore(this.props.score);
            this.setPointerDeg(this.getDeg(), 2.5, 'cubic-bezier(0, 0, 0.2, 1)');
            this.pointerRef.current.getElementById('pointerCircle1').style.stroke = range.color;
            this.pointerRef.current.getElementById('pointerCircle2').style.fill = range.color;
          } else {
            deg = -1 * deg;
            if (deg > 0) {
              this.setPointerDeg(deg, 1.6, 'ease');
            } else {
              this.setPointerDeg(deg, 1.6, 'cubic-bezier(0, 0, 0.2, 1)');
            }
          }
        }, 1600)
      }, 1000);
    }
  }

  render() {
    const {
      loading,
      helpText,
      score,
      number,
      region
    } = this.props;
    const {speedoShown, animationFinished} = this.state;
    const range = this.getRangeByScore(speedoShown ? score : null);

    return (
      <div className="score-graph">
        <div>
          <Text color={TextColor.ABSOLUTE_BLACK}
                className={classNames('score-graph__title', animationFinished ? 'score-graph__title_animated' : '')}
                uppercase={true}>
            {
              animationFinished
                ? <>Вероятность ДТП в&nbsp;2020&nbsp;году</>
                : <>Узнай Вероятность ДТП<br />для своего грузовика</>
            }
          </Text>
          <div className="score-graph__mobile-auto-number">
            {animationFinished &&
            <div className="score-graph__number">
              <Text size={TextSize.S_14}
                    className="score-graph__number-title"
                    color={TextColor.GRAY}
                    uppercase={true}>гос. номер грузовика</Text>
              <CarNumber number={number} region={region} readonly={true}/>
            </div>}
          </div>
          <div className='score-graph__svg-container'>
            <ScoreGraphSvg className="score-graph__main-svg"/>
            <Pointer ref={this.pointerRef} className='score-graph__arrow-with-circle-svg'/>

            <div className='score-graph__text-group'>
              {!speedoShown && <DottedSvg className="score-graph__dotted"/>}
              {speedoShown && <Speedo end={score} className='score-graph__text-group-score-text'><span className="score-graph__text-group-score-text-percent">%</span></Speedo>}
              <Text className='score-graph__text-group-helpText' color={animationFinished && range && range.className}>{helpText || (animationFinished && range && range.caption)}</Text>
            </div>

            {animationFinished && <Legend/>}

          </div>
        </div>
        {!animationFinished &&
        <div className="score-graph__technologies">
          <Text size={TextSize.S_16} color={TextColor.DARK_GRAY}>На основе технологий </Text>
          <LogoSvg className="score-graph__logo"/>
          <Text size={TextSize.S_16} color={TextColor.DARK_GRAY}>и</Text>
          <LogoIngosSvg className="score-graph__logo-ingos"/>
        </div>}
      </div>
    );
  }
}