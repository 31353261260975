import React from 'react';
import './main-panel.scss';
import {ScoreGraph} from '../score-graph/ScoreGraph';
import {AutoForm} from '../auto-form/AutoForm';
import classNames from 'classnames';
import {AutoData} from '../auto-data/AutoData';
import {Button} from '../button/Button';
import {Text, TextColor, TextSize} from '../text/Text';
import {ReactComponent as LogoSvg} from '../../svg/logo.svg';
import {ReactComponent as LogoIngosSvg} from '../../svg/logo-ingos.svg';
import {isCarNumberValid} from '../../common/validation/isCarNumberValid';
import {isEmailValid} from '../../common/validation/isEmailValid';
import {Popup} from '../popup/Popup';
import {getPredictionStatus, initPrediction} from './MainPanelModel';
import api from '../../api';
import AppContext from 'store/context';
import MainPanelOSAGOAuth from './components/osago-auth';

function isMobile() {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
}

export class MainPanel extends React.Component {
  state = {
    formAnimation: false,
    scoreAnimation: false,
    score: null,
    number: this.props.number || '',
    region: this.props.region || '',
    email: this.props.email || '',
    checked: true,
    predictionId: '',
    isShowPopup: false,
  };

  popupRef = React.createRef();
  numberRef = React.createRef();

  handleHistoryRefresh = () => {
    this.props.refreshHistory();
  };

  handleButtonClick = async () => {
    const {number, region, email} = this.state;
    this.setState({
      scoreAnimation: true,
      score: null
    });
    if (isMobile()) {
      window.scrollTo(0, 280);
      window.scrollTo(0, 300);
    }

    const response = await api('/prediction/init', 'POST', {
      plates: `${number}${region}`,
      email,
    });

    const data = await response.json();
    if (data && data.predictionId) {
      const predictionId = data.predictionId;
      this.setState({predictionId: data.predictionId});
      const interval = setInterval(async () => {
        const response = await getPredictionStatus(predictionId);
        const data = await response.json();
        if (data && data.isCompleted) {
          if (!data.isFaulted) {
            this.setState({score: (data.info.rate * 100).toFixed(2)});
            this.setState({
              info: data.info
            });

            this.handleHistoryRefresh();
          } else {
            this.popupRef.current.showError(data.errorCode, data.error);
            this.setState({
              scoreAnimation: false,
              number: '',
              region: ''
            });
          }
          clearInterval(interval);
        }
      }, 1000)
    } else {
      this.popupRef.current.showError(data.errorCode, data.error);
      this.setState({
        scoreAnimation: false,
        number: '',
        region: ''
      });
    }
  };

  handleNumberChange = (number) => {
    this.setState({number});
  };

  handleRegionChange = (region) => {
    this.setState({region});
  };

  handleEmailChange = (email) => {
    this.setState({email});
  };

  handleCheckedChange = (checked) => {
    this.setState({checked});
  };

  drawUpOsago = () => {
    if (this.context.user && this.context.user.phone) {
      this.showOsagoAuth();
    } else {
      const { predictionId } = this.state;
      this.popupRef.current.show('initial', predictionId);
    }
  };

  getHelpText() {
    const {number, region, email, checked, score, scoreAnimation} = this.state;
    if (!isCarNumberValid(number, region)) {
      return <>заполните<br />данные</>
    } else if (!isEmailValid(email)) {
      return <>введи<br/>почту</>
    } else if (!checked) {
      return <>дай<br/>согласие</>
    } else if (!score && !scoreAnimation) {
      return <>система<br/>готова</>
    } else if (scoreAnimation) {
      return <>вычисляем</>
    } else {
      return '';
    }
  }

  handleAnimationFinished = () => {
    this.setState({formAnimation: true, helpText: '', scoreAnimation: false});
  };

  handleErrorHide = () => {
    this.numberRef.current.focus();
  };

  handleAcceptClick = () => {
    this.setState({
      isShowPopup: false,
    });
  };

  showOsagoAuth = () => {
    this.setState({
      isShowPopup: true,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.email !== this.props.email && this.context.user && this.context.user.email) {
      this.setState({
        email: this.context.user.email,
      })
    }

  }

  render() {
    const {formAnimation, score, scoreAnimation, number, region, email, checked, info = {}} = this.state;
    const {make, model, manufacturedOn, vin} = info;
    return (
      <div className={classNames('main-panel', score ? 'main-panel_score' : '')}>
        <Popup ref={this.popupRef} onErrorHide={this.handleErrorHide}/>
        <div className="main-panel__row">
          <ScoreGraph loading={scoreAnimation}
                      score={score}
                      number={number}
                      region={region}
                      onAnimationFinished={this.handleAnimationFinished}
                      helpText={this.getHelpText()}/>
          <div className={classNames('main-panel__form-container', formAnimation ? 'main-panel__form-container_animated' : '')}>
            <AutoForm number={number}
                      region={region}
                      email={email}
                      checked={checked}
                      numberRef={this.numberRef}
                      disabled={scoreAnimation}
                      onNumberChange={this.handleNumberChange}
                      onRegionChange={this.handleRegionChange}
                      onEmailChange={this.handleEmailChange}
                      onCheckedChange={this.handleCheckedChange}
                      onButtonClick={this.handleButtonClick}/>
          </div>
          <div className={classNames('main-panel__data-container', formAnimation ? 'main-panel__data-container_animated' : '')}>
            <AutoData number={number} region={region} make={make} model={model} manufacturedOn={manufacturedOn} vin={vin}/>
          </div>
          <div className={classNames('main-panel__mobile-data-container', formAnimation ? 'main-panel__mobile-data-container_animated' : '')}>
            <AutoData withoutCarNumber={true} className="main-panel__mobile-auto-data" number={number} region={region} make={make} model={model} manufacturedOn={manufacturedOn} vin={vin}/>
            <Button onClick={this.drawUpOsago}>Оформить полис ОСАГО</Button>
          </div>
        </div>

        <div className={classNames('main-panel__osago-container', formAnimation ? 'main-panel__osago-container_animated' : '')}>
          <div className="main-panel__osago">
            <Button onClick={this.drawUpOsago}>Оформить полис ОСАГО</Button>
            <div className="score-graph__technologies main-panel__technologies">
              <Text size={TextSize.S_16} color={TextColor.DARK_GRAY}>На основе технологий </Text>
              <LogoSvg className="score-graph__logo"/>
              <Text size={TextSize.S_16} color={TextColor.DARK_GRAY}>и</Text>
              <LogoIngosSvg className="score-graph__logo-ingos"/>
            </div>
          </div>
        </div>
        <div className="main-panel__mobile-osago-container">
          <Text size={TextSize.S_16} color={TextColor.DARK_GRAY}>На основе технологий </Text>
          <div className="main-panel__mobile-osago-row">
            <LogoSvg className="score-graph__logo"/>
            <Text size={TextSize.S_16} color={TextColor.DARK_GRAY}>и</Text>
            <LogoIngosSvg className="score-graph__logo-ingos"/>
          </div>
        </div>

        {
          this.state.isShowPopup
            ? <MainPanelOSAGOAuth
                predictionId={ this.state.predictionId }
                onAcceptClick={ this.handleAcceptClick }
              />
            : null
        }
      </div>
    );
  }

}

MainPanel.contextType = AppContext;
