import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import AppContext from 'store/context';
import { getUserInfo, profileLogout } from "../main-panel/MainPanelModel";
import IconSprite from 'components/icon-sprite';
import MustPopup from 'components/mustPopup';

// Local Components
import LoginPhone from './components/login-phone';
import LoginSMS from './components/login-sms';

import './profile-btn.scss';
import {isLoggedIn, removeToken} from "../../modules/auth";

class ProfileBtn extends PureComponent {
	state = {
		otpState: '',
		phoneNumber: '',
		smsCodeAwait: false,
		countdown: 1,
		smsCode: '',
		error: null,
	};

	logout = (context) => async (e) => {
		e.preventDefault();

		await profileLogout();

		removeToken()

		context.setData({user: null});
	};

	setUserName = async () => {
		if (isLoggedIn()) {
			const response = await getUserInfo();

			if (response.status === 200) {
				const user = await response.json();

				this.props.setData({user})
			}
		}
	};

	onSuccess = () => {
		this.setUserName();

		this.setState({
			isShowPopup: false,
			smsCodeAwait: false,
		});
	};

	onWaitCode = (otpState, phoneNumber) => {
		this.setState({
			otpState: otpState,
			phoneNumber: phoneNumber,
			smsCodeAwait: true,
		});
	};

	toggleLoginPopup = () => {
		this.setState((state) => ({
			isShowPopup: !state.isShowPopup,
			smsCodeAwait: false,
		}));
	};

	render() {
		const {
			isShowPopup,
			smsCodeAwait,
			phoneNumber,
			otpState,
		} = this.state;

		const headerText = smsCodeAwait
			? (<span>мы отправили СМС с кодом <br />на номер +7({phoneNumber.substr(0, 3)})***-**-{phoneNumber.substr(8, 2)}</span>)
			: (<span>введи номер телефона <br />вашего аккаунта</span>);

		if (this.props.user) {
			return (
				<div className="profile-btn">
					<Link
						to='/profile'
						className="profile-btn__button"
					>
						<span className="profile-btn__icon">
							<IconSprite name="empty-user"/>
						</span>
						<span className="profile-btn__name">{ this.props.user.firstName || this.props.user.phone }</span>
					</Link>

					<div className="profile-btn__popup">
						<div className="profile-btn__popup-item">
							<Link
								to='/profile'
								className='profile-btn__popup-link profile-btn__popup-link_main'
							>
								<span>Мой профиль</span>
								<span className='profile-btn__popup-link-icon'>
									<IconSprite name="right-arrow"/>
								</span>
							</Link>
						</div>

						<div className="profile-btn__popup-item">
							<AppContext.Consumer>
								{
									(context) => (
										<a
											href='#'
											className='profile-btn__popup-link'
											onClick={ this.logout(context) }
										>Выйти</a>
									)
								}
							</AppContext.Consumer>
						</div>
					</div>
				</div>
			)
		}

		return (
			<>
				<div className="profile-btn">
					<button
						onClick={ this.toggleLoginPopup }
						className="profile-btn__button"
						type="button"
					>
						<div className="profile-btn__icon">
							<IconSprite name="empty-user"/>
						</div>
						<div className="profile-btn__name" id={'loginBtn'}>Войти</div>
					</button>
				</div>

				{
					isShowPopup
						? (
							<MustPopup
								header={ headerText }
								onClose={ this.toggleLoginPopup }
							>
								{
									smsCodeAwait
										? <LoginSMS
												otpState={ otpState }
												phoneNumber={ phoneNumber }
												onSuccess={ this.onSuccess }
											/>
										: <LoginPhone
											    onClose={ this.toggleLoginPopup }
												onWaitCode={ this.onWaitCode }
											/>
								}
							</MustPopup>
						)
						: null
				}
			</>
		)
	}
}

export default ProfileBtn;
