import React from 'react';
import './carousel.scss';
import {CarouselItem} from '../carousel-item/CarouselItem';
import {ReactComponent as AlgorithmSvg} from '../../svg/algorithm.svg';
import {ReactComponent as Svg2} from '../../svg/2.svg';
import {ReactComponent as Svg3} from '../../svg/3.svg';

export class Carousel extends React.Component {

  render() {
    return (
      <div className="carousel">
        <CarouselItem svg={<AlgorithmSvg/>} text="высшая математика + машинное обучение" subText="расчеты и прогнозы основаны на самых инновационных методах"/>
        <CarouselItem svg={<Svg2/>} text="система анализирует 140+ параметров авто" subText="данные из 19 официальных источников и открытых систем"/>
        <CarouselItem svg={<Svg3/>} text="десятки тысяч аварий&nbsp;по&nbsp;России" subText="детальный анализ причин, условий и обстоятельств ДТП"/>
      </div>
    );
  }

}
