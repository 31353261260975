import React from 'react';
import {func, string, bool} from 'prop-types';
import './checkbox.scss';

export class Checkbox extends React.Component {

  static propTypes = {
    onChange: func,
    name: string,
    label: string,
    checked: bool,
    disabled: bool
  };

  render() {
    const {name, onChange, label, checked, disabled} = this.props;
    return (
      <div className='checkbox-container'>
        <label className="control control-checkbox" htmlFor={name}>
          <div className="control-checkbox label">{label}</div>

          <input disabled={disabled} type="checkbox" checked={checked} id={name} name={name} onChange={onChange}/>
          <div className="control_indicator"></div>
        </label>
      </div>
    );
  }
}