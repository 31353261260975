import React from 'react';
import {func, string, bool} from 'prop-types';
import './button.scss';
import classNames from 'classnames';

export class Button extends React.Component {

  static propTypes = {
    onClick: func,
    className: string,
    disabled: bool
  };

  render() {
    const {children, onClick, className, disabled} = this.props;
    return (
      <div className={classNames('button', disabled ? 'button_disabled' : '', className)}
           onClick={onClick}>
        <div>
          {children}
        </div>
      </div>
    );
  }

}
