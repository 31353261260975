import React from 'react';
import './car-number.scss';
import {bool, func, string} from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';
import {ReactComponent as EllipseSvg} from '../../svg/ellipse.svg';
import {ReactComponent as EllipseRedSvg} from '../../svg/ellipse-red.svg';
import {ReactComponent as RusSvg} from '../../svg/rus.svg';
import {ErrorBubble} from '../error-bubble/ErrorBubble';

const LETTERS = ['А', 'В', 'Е', 'К', 'М', 'Н', 'О', 'Р', 'С', 'Т', 'У', 'Х', 'A', 'B', 'E', 'K', 'M', 'H', 'O', 'P', 'C', 'T', 'Y', 'X'];
const DIGITS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export class CarNumber extends React.Component {

  static propTypes = {
    onNumberChange: func,
    onRegionChange: func,
    onEnterPress: func,
    number: string,
    region: string,
    readonly: bool
  };

  static defaultProps = {
    onNumberChange() {
    },
    onRegionChange() {
    },
    onEnterPress() {

    },
  };

  state = {
    validationDigitShown: false,
    validationLetterShown: false,
  };

  regionInputRef = React.createRef();
  numberHiddenInputRef = React.createRef();

  handleNumberChange = (e) => {
    const {onNumberChange} = this.props;
    const number = e.target.value;
    onNumberChange(number);
    if (size(number) === 6) {
      this.regionInputRef.current.focus();
    }
  };

  handleRegionChange = (e) => {
    const {onRegionChange} = this.props;
    const region = e.target.value;
    onRegionChange(region);
  };

  handleNumberClick = () => {
    if (this.props.numberRef) {
      this.props.numberRef.current.focus();
    }
  };

  componentDidUpdate = () => {
    const {number} = this.props;
    if (!isEmpty(number)) {
      const letterSpacing = Math.floor(((-0.18278 * this.numberHiddenInputRef.current.offsetWidth * 6) / (size(number)) + 47.8684));
      if (this.props.numberRef) {
        this.props.numberRef.current.style.letterSpacing = `${letterSpacing}px`;
      }
    }
  };


  handleNumberKeyPress = (e) => {
    const {number} = this.props;
    let allowedSymbols;
    if (size(number) === 0 || size(number) === 4 || size(number) === 5) {
      allowedSymbols = LETTERS;
    } else {
      allowedSymbols = DIGITS;
    }
    if (!includes(allowedSymbols, toUpper(e.key))) {
      e.preventDefault();
      e.stopPropagation();
      if (size(number) < 6) {
        this.setState({validationDigitShown: allowedSymbols === DIGITS, validationLetterShown: allowedSymbols === LETTERS});
      }
    } else {
      this.setState({validationDigitShown: false, validationLetterShown: false});
    }

    if (e.key === 'Enter') {
      this.props.onEnterPress();
    }
  };

  handleNumberKeyDown = (e) => {
    if (e.keyCode >= 37 && e.keyCode <= 40) {
      e.preventDefault();
    }
  };

  handleRegionKeyPress = (e) => {
    const {region} = this.props;
    if (!includes(DIGITS, toUpper(e.key))) {
      e.preventDefault();
      e.stopPropagation();
      if (size(region) < 2) {
        this.setState({validationDigitShown: true, validationLetterShown: false});
      }
    } else {
      this.setState({validationDigitShown: false, validationLetterShown: false});
    }

    if (e.key === 'Enter') {
      this.props.onEnterPress();
    }
  };

  handleOnPaste = (e) => {
    const {onRegionChange, number, onNumberChange} = this.props;
    const text = e.clipboardData.getData('Text') || '';
    if (!includes(LETTERS, toUpper(text[0]))
      || !includes(DIGITS, toUpper(text[1]))
      || !includes(DIGITS, toUpper(text[2]))
      || !includes(DIGITS, toUpper(text[3]))
      || !includes(LETTERS, toUpper(text[4]))
      || !includes(LETTERS, toUpper(text[5]))) {
      e.preventDefault();
      return;
    }
    if (size(number) > 0) {
      e.preventDefault();
      onNumberChange(text.slice(0, 6));
    }
    this.setState({validationDigitShown: false, validationLetterShown: false});
    if (size(text) > 6) {
      const region = text.slice(6);
      if (/^\d+$/.test(region)) {
        onRegionChange(region.slice(0, 3));
      }
    }
  };

  handleRegionBlur = () => {
    this.setState({validationDigitShown: false, validationLetterShown: false});
  };

  render() {
    const {number, region, readonly, numberRef} = this.props;
    const {validationDigitShown, validationLetterShown} = this.state;
    return (
      <div className={classNames('car-number', (validationDigitShown || validationLetterShown) ? 'car-number_error' : '')}>
        {
          readonly
            ? null
            : <>
                <ErrorBubble shown={validationDigitShown}
                             text="Введите цифру"
                             small={true}
                             className="car-number__error-bubble car-number__error-bubble_small"/>
                <ErrorBubble shown={validationLetterShown}
                             text={<>Используйте буквы: A&nbsp;B&nbsp;E&nbsp;K&nbsp;M&nbsp;H&nbsp;O&nbsp;P&nbsp;C&nbsp;T&nbsp;У&nbsp;X </>}
                             className="car-number__error-bubble"/>
              </>
        }

        {(validationDigitShown || validationLetterShown) && <EllipseRedSvg className="car-number__left-ellipse"/>}
        {!validationDigitShown && !validationLetterShown && <EllipseSvg className="car-number__left-ellipse"/>}
        <input className="car-number__number-input"
               ref={numberRef}
               onChange={this.handleNumberChange}
               maxLength="6"
               inputMode={size(number) >= 1 && size(number) < 4 ? 'decimal' : 'text'}
               onKeyPress={this.handleNumberKeyPress}
               onKeyDown={this.handleNumberKeyDown}
               onPaste={this.handleOnPaste}
               disabled={readonly}
               value={number}/>
        <div className="car-number__number" onClick={this.handleNumberClick}>
          <div className={classNames('car-number__small-character', number[0] ? 'car-number__active-character' : '')}>
            {number[0] ? number[0] : 'a'}
          </div>
          <div className="car-number__digits-row">
            <div className={classNames('car-number__big-character', number[1] ? 'car-number__active-character' : '')}>
              {number[1] ? number[1] : '1'}
            </div>
            <div className={classNames('car-number__big-character', number[2] ? 'car-number__active-character' : '')}>
              {number[2] ? number[2] : '2'}
            </div>
            <div className={classNames('car-number__big-character', number[3] ? 'car-number__active-character' : '')}>
              {number[3] ? number[3] : '3'}
            </div>
          </div>
          <div className={classNames('car-number__small-character', number[4] ? 'car-number__active-character' : '')}>
            {number[4] ? number[4] : 'a'}
          </div>
          <div className={classNames('car-number__small-character', number[5] ? 'car-number__active-character' : '')}>
            {number[5] ? number[5] : 'a'}
          </div>
        </div>
        <span className="car-number__number-hidden" ref={this.numberHiddenInputRef}>
          {number}
        </span>
        <div className={classNames('car-number__region-container', (validationDigitShown || validationLetterShown) ? 'car-number__region-container_error' : '')}>
          <input className="car-number__region-input"
                 value={region}
                 type="text"
                 maxLength={3}
                 placeholder="123"
                 onKeyPress={this.handleRegionKeyPress}
                 ref={this.regionInputRef}
                 disabled={readonly}
                 inputMode="decimal"
                 onBlur={this.handleRegionBlur}
                 onChange={this.handleRegionChange}/>
          <RusSvg className="car-number__rus"/>
        </div>
        {(validationDigitShown || validationLetterShown) && <EllipseRedSvg className="car-number__right-ellipse"/>}
        {!validationDigitShown && !validationLetterShown && <EllipseSvg className="car-number__right-ellipse"/>}
      </div>
    );
  }

}
