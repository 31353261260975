import React, { PureComponent } from 'react';
import Cookies from 'js-cookie';
import AppContext from '../../store/context';

import IconSprite from '../../components/icon-sprite';
import MustPopup from '../mustPopup';

// Local Components
import RegistrationMain from './components/registration-main';
import RegistrationSMS from './components/registration-sms';

import './registration.scss';
import api from "../../api";
import {isLoggedIn} from "../../modules/auth";

class Registration extends PureComponent {
	state = {
		otpState: '',
		phoneNumber: '',
		name: '',
		smsCodeAwait: false,
		isShowPopup: false,
	};

	setUserName = async (context) => {
		const response = await api('/profile', 'POST', {
			firstName: this.state.name,
		});

		if (isLoggedIn() && response.status === 200) {
			const user = await response.json();

			context.setData({user});
		}
	};

	onSuccess = (context) => () => {
		this.setUserName(context);

		this.setState({
			isShowPopup: false,
			smsCodeAwait: false,
		});
	};

	onWaitCode = (otpState, phoneNumber, name) => {
		name = name || this.state.name;

		this.setState({
			otpState: otpState,
			phoneNumber: phoneNumber,
			name: name,
			smsCodeAwait: true,
		});
	};

	toggleRegistrationPopup = () => {
		this.props.onClose(!this.state.isShowPopup);

		this.setState((state) => ({
			isShowPopup: !state.isShowPopup,
			smsCodeAwait: false,
		}));
	};

	componentDidUpdate(prevProps) {
		if (this.props.toggleShow && !this.state.isShowPopup) {
			this.toggleRegistrationPopup();
		}
	}

	render() {
		const {
			isShowPopup,
			smsCodeAwait,
			otpState,
			phoneNumber,
		} = this.state;

		const headerText = smsCodeAwait
			? (<span>мы отправили СМС с кодом <br />на номер +7({phoneNumber.substr(0, 3)})***-**-{phoneNumber.substr(8, 2)}</span>)
			: (<span>введите данные,<br />чтобы создать аккаунт</span>);

		return (
			<AppContext.Consumer>
				{
					(context) => (
						<>
							<div className="registration">
								<button
									onClick={ this.toggleRegistrationPopup }
									className="registration__button"
									id={'registationBtn'}
									type="button"
								>
									<span className="registration__button-text">Я новый пользователь</span>
									<span className="registration__button-icon">
										<IconSprite name="right-arrow"/>
									</span>
								</button>
							</div>

							{
								isShowPopup
									? (
										<MustPopup
											header={ headerText }
											onClose={ this.toggleRegistrationPopup }
										>
											{
												smsCodeAwait
													? <RegistrationSMS
															otpState={ otpState }
															phoneNumber={ phoneNumber }
															onSuccess={ this.onSuccess(context) }
														/>
													: <RegistrationMain
															onClose={ this.toggleRegistrationPopup }
															onWaitCode={ this.onWaitCode }
														/>
											}
										</MustPopup>
									)
									: null
							}
						</>
					)
				}
			</AppContext.Consumer>
		)
	}
}

export default Registration;
