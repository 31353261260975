import React from 'react';
import './sms-code.scss';
import { func, string, bool } from 'prop-types';
import classNames from 'classnames';

export class SmsCode extends React.Component {

  static propTypes = {
    onSmsCodeChange: func,
    onEnter: func,
    smsCode: string
  };

  static defaultProps = {
    onSmsCodeChange() {
    },
    onEnter() {
    }
  };

  smsCodeInputRef = React.createRef();
  smsCodeHiddenInputRef = React.createRef();

  handleSmsCodeChange = (e) => {
    const { onSmsCodeChange } = this.props;
    const smsCode = e.target.value;
    onSmsCodeChange(smsCode);
  };

  // handleSmsCodeClick = () => {
  //   this.smsCodeInputRef.current.focus();
  // };

  handleSmsCodeKeyDown = (e) => {
    if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 46 && e.keyCode !== 8 && e.key !== 'Enter') {
      e.preventDefault();
    }
  };

  handleKeyPress = (e) => {
    const {onEnter} = this.props;
    if (e.key === 'Enter') {
      onEnter();
    }
  };

  render() {
    const { smsCode } = this.props;
    return (
      <div className={'sms-code'}>
        <input className="sms-code__smsCode-input"
          ref={this.smsCodeInputRef}
          onChange={this.handleSmsCodeChange}
          onKeyPress={this.handleKeyPress}
          onFocus={this.onFocus}
          inputMode="decimal"
          maxLength="4"
          placeholder="1234"
          onKeyDown={this.handleSmsCodeKeyDown}
          value={smsCode} />
        {/* <div className="sms-code__smsCode" onClick={this.handleSmsCodeClick}>
          <div className="sms-code__digits-row">
            <div className={classNames('sms-code__big-character', smsCode[0] ? 'sms-code__active-character' : '')}>
              {smsCode[0] ? smsCode[0] : '1'}
            </div>
            <div className={classNames('sms-code__big-character', smsCode[1] ? 'sms-code__active-character' : '')}>
              {smsCode[1] ? smsCode[1] : '2'}
            </div>
            <div className={classNames('sms-code__big-character', smsCode[2] ? 'sms-code__active-character' : '')}>
              {smsCode[2] ? smsCode[2] : '3'}
            </div>
            <div className={classNames('sms-code__big-character', smsCode[3] ? 'sms-code__active-character' : '')}>
              {smsCode[3] ? smsCode[3] : '3'}
            </div>
          </div>
          <span className="sms-code__smsCode-hidden" ref={this.smsCodeHiddenInputRef}>
            {smsCode}
          </span>
        </div> */}
      </div>
    );
  }
}
