import Main from './pages/main';
import History from './pages/history';
import Profile from './pages/profile';

export default [
  {
    path: '/',
    component: Main,
    exact: false,
  },
  {
    path: '/history',
    component: History,
    exact: true,
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
  }
];
