import api from "../../api";

export function initPrediction(number, region, email) {
  return api('/prediction/init', 'POST', {plates: `${number}${region}`, email})
}


export function getPredictionStatus(predictionId) {
  return api(`/prediction/status/${predictionId}`)
}

export function initOtp(phone) {
  return api('/auth/otp/', 'POST', {phone})
}

export function login(phone, otpState, otp) {
  return api('/auth/login/jwt', 'POST', { phone, otpState, otp })
}

export function apply(predictionId, name) {
  return api('/prediction/apply', 'POST', { predictionId, name })
}

export function getUserInfo() {
  return api('/profile');
}

export function changeUserData(firstName, lastName, email) {
  return api('/profile', 'POST', {
    firstName,
    lastName,
    email,
  });
}

export function profileLogout() {
  return api('/auth/logout', 'POST');
}
