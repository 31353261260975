import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as CloseSvg } from '../../svg/close.svg';
import MustPopupHeader from './mustPopupHeader/';

import './must-popup.scss';

class MustPopup extends PureComponent {
	constructor(props) {
		super(props);

		this.root = document.createElement('div');
		this.root.className = 'must-popup';
		document.body.appendChild(this.root);
	}

	componentDidMount() {
		document.addEventListener('keyup', this.keyWatcher);
	};

	componentWillUnmount() {
		document.body.removeChild(this.root);
		document.removeEventListener('keyup', this.keyWatcher);
	};

	keyWatcher = (e) => {
		if (e.key === 'Escape') {
			this.handleClose(e);
		}
	};

	handleClose = (e) => {
		this.props.onClose(e);
	};

	render() {
		const popupMarkup = (
			<>
				<div className="must-popup__container">
					<div
						className="must-popup__bg"
						onClick={ this.handleClose }
					/>
					<div className="must-popup__inner">
						<CloseSvg
							className='popup__closeSvg'
							onClick={ this.handleClose }
						/>
						<MustPopupHeader>{ this.props.header }</MustPopupHeader>
						<div className="must-popup-view">
							{this.props.children}
						</div>
					</div>
				</div>
			</>
		);

		return ReactDOM.createPortal(popupMarkup, this.root);
	}
}

MustPopup.defaultProps = {
	header: ''
};

export default MustPopup;