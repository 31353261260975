import React from 'react';
import {bool, element, oneOfType, string} from 'prop-types';
import {ReactComponent as ErrorBubbleSvg} from '../../svg/error-bubble.svg';
import {ReactComponent as ErrorBubbleSmallSvg} from '../../svg/error-bubble-small.svg';
import {ReactComponent as ErrorTailSvg} from '../../svg/error-tail.svg';
import './error-bubble.scss';
import classNames from 'classnames';

export class ErrorBubble extends React.Component {

  static propTypes = {
    text: oneOfType([string, element]),
    className: string,
    small: bool,
    shown: bool
  };

  render() {
    const {text, className, small, shown} = this.props;
    return (
      <div className={classNames('error-bubble', shown ? 'error-bubble__shown' : '', className)}>
        <div className="error-bubble__desktop">
          {small && <ErrorBubbleSmallSvg/>}
          {!small && <ErrorBubbleSvg/>}
          <div className={classNames('error-bubble__rectangle', small ? 'error-bubble__rectangle_small' : '')}>
            {text}
          </div>
        </div>
        <div className={classNames('error-bubble__mobile', small ? 'error-bubble__mobile_small' : '')}>
          <div className={classNames('error-bubble__rectangle', small ? 'error-bubble__rectangle_small' : '')}>
            {text}
          </div>
          <ErrorTailSvg className="error-bubble__tail"/>
        </div>
      </div>
    );
  }

}
