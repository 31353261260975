import React from 'react';
import './countdown.scss';
import { bool, func, string } from 'prop-types';

export class Countdown extends React.Component {
    state = {
        seconds: 30
    }

    static propTypes = {
        onTimeOver: func
    };

    componentDidMount() {
        const { onTimeOver } = this.props;
        this.myInterval = setInterval(() => {
            const { seconds } = this.state;
            this.setState(({ seconds }) => ({
                seconds: seconds - 1
            }))

            if (seconds <= 1) {
                clearInterval(this.myInterval);
                onTimeOver();
            }

        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        const { seconds } = this.state
        return (<span className="countdown">{seconds}</span>)
    }
}