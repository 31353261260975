import React from 'react';

const IconSprite = ({
  className,
  name,
  ...clearProps
}) => {
  return (
    <svg
      className={ className }
      {...clearProps}
    >
      <use xlinkHref={`#${ name }`} />
    </svg>
  );
};

export default IconSprite;