import React from 'react';
import './popup-inner-view.scss';
import {Button} from '../../button/Button';
import {func, number, string} from 'prop-types';
import {SmsCode} from '../../sms-code/SmsCode';
import {Text, TextColor, TextFont, TextSize} from '../../text/Text';
import {Countdown} from '../../countdown/Countdown';
import {apply, initOtp, login} from '../../main-panel/MainPanelModel';
import classNames from 'classnames';
import size from 'lodash/size';
import {saveToken} from "../../../modules/auth";

export class SmsCodeInnerView extends React.Component {
  state = {
    smsCode: '',
    realSmsCode: '',
    error: null,
    countdown: 1
  }

  static propTypes = {
    onClick: func,
    onSuccess: func,
    onUpdateSmsCode: func,
    phoneNumber: number,
    otpState: number,
    predictionId: string,
    name: string,
  }

  onCountdownEnd = () => {
    this.setState({countdown: 0});
  }

  onSmsCodeChange = (smsCode) => {
    this.setState({smsCode: smsCode});
    if (size(smsCode) === 4) {
      this.sendData(smsCode)
    }
  }

  onClick = () => {
    // const {smsCode} = this.state;
    // if (!this.isDataValid()) {
    //   return;
    // }
    // this.sendData(smsCode)
  };

  sendData = async (smsCode) => {
    const {otpState, phoneNumber, predictionId, name, onSuccess} = this.props;
    const response = await login('+7' + phoneNumber, otpState, smsCode);

    const data = await response.json();
    if (response.status === 400) {
      if (data && data.errorCode) {
        if (data.errorCode == 1005) {
          this.setState({error: 'Введен неверный код из смс'});
        } else {
          this.setState({error: 'Сервер недоступен'});
        }
      }
    } else {
      saveToken(data.token)

      const applyResponse = await apply(predictionId, name);
      if (applyResponse.status === 200) {
        onSuccess();
      }
    }
  }

  updateSmsCode = async () => {
    const {otpState, phoneNumber, onUpdateSmsCode} = this.props;
    this.setState({smsCode: ''});
    const response = await initOtp('+7' + phoneNumber);
    const data = await response.json();

    if (data) {
      if (data.otpState) {
        this.setState({
          otpState: data.otpState,
        });
        onUpdateSmsCode(data.otpState, phoneNumber);
      } else {
        this.setState({
          errorCode: data.errorCode,
        });
      }
      this.setState({countdown: 1});
    }
  }


  isDataValid = () => {
    const {smsCode} = this.state;
    return smsCode.length === 4;
  }

  render() {
    const {smsCode, error, countdown} = this.state;

    return (
      <div className="popup-inner-view">
        <div className="popup-inner-view__item">
          <div className="popup-inner-view__title">
            <Text color={TextColor.GRAY} size={TextSize.S_14} uppercase={true} className="popup-inner-view__digit">03</Text>
            <Text color={TextColor.ABSOLUTE_BLACK} size={TextSize.S_14} uppercase={true}>Код из СМС</Text>
          </div>

          <SmsCode
            onSmsCodeChange={this.onSmsCodeChange}
            onEnter={this.onClick}
            smsCode={smsCode}
          />

          {error && <div className="popup-inner-view__item">
            <Text color={TextColor.RED}>{error}</Text>
          </div>}
          {countdown !== 0 && <div className="popup-inner-view__item">
            <Text size={TextSize.S_12} font={TextFont.UBUNTU} className="popup-inner-view__countdown-text">Запросить код повторном можно через <Countdown onTimeOver={this.onCountdownEnd}/> сек </Text>
          </div>
          }
          {countdown === 0 && <div className="popup-inner-view__item">
            <Button onClick={this.updateSmsCode} className='popup-inner-view__no-border-button'>Запросить новый код</Button>
          </div>
          }
        </div>

        <div className={classNames('popup-inner-view__item', !this.isDataValid() ? 'popup__disabled' : '')}>
          <Button onClick={this.onClick}>Подтвердить</Button>
        </div>
      </div>
    );
  }
}
