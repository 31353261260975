import React from 'react';
import { Link } from 'react-router-dom';

import IconSprite from 'components/icon-sprite';

import './arrow-link.scss';

const ArrowLink = ({
	to = '/',
	children,
}) => {
	return (
		<Link
			to={ to }
			className="arrow-link"
		>
			<span className="arrow-link__text">{ children }</span>
			<span className="arrow-link__icon">
				<IconSprite name="right-arrow"/>
			</span>
		</Link>
	)
};

export default ArrowLink;
