import React from 'react';
import './popupHeader.scss';
import { ReactComponent as ShturmanSvg } from '../../../svg/shturman.svg';
import { ReactComponent as FollowSvg } from '../../../svg/follow.svg';
import {func, number, string} from 'prop-types';
import classNames from 'classnames';

export class PopupHeader extends React.Component {
    static propTypes = {
        innerView: string,
        errorCode: number,
        phoneNumber: string
    }

    getBackgroundColorClassName = () => {
        const { errorCode } = this.props;
        if (!errorCode)
            return '';

        switch (errorCode) {
            case 1102:
            case 1103:
            case 1204:
                return 'popup-header__yellow';
            case 1201:
            case 1202:
            case 1203:
            case 1301:
                return 'popup-header__red';
            default:
                return '';
        }
    }

    renderHeaderSubText = () => {
        const { innerView, errorCode, phoneNumber } = this.props;
        if (errorCode) {
            switch (errorCode) {
                case 1102:
                case 1103:
                case 1204:
                    return <span> гос.номер содержит ошибки <br />или принадлежит <br />легковому автомобилю</span>;
                case 1201:
                case 1202:
                case 1203:
                case 1301:
                    return <span> расчёт вероятности ДТП<br /> для указанного гос. номера<br /> не доступен</span>;
                default:
                    return <span> расчёт вероятности ДТП<br /> для указанного гос. номера<br /> не доступен</span>;
            }
        }
        
        switch (innerView) {
            case 'initial':
                return <span>оставь свои данные<br />и я назначу тебе <br /> страхового агента</span>;
            case 'smsCode':
                return <span>мы отправили СМС с кодом<br />на номер +7({phoneNumber.substr(0, 3)})***-**-{phoneNumber.substr(8, 2)}</span>;
            case 'sucsess':
                return <span>я назначил тебе страхового <br />агента, он позвонит<br />в ближайшее время</span>;
            default:
                return <span>оставь свои данные<br />и я назначу тебе <br /> страхового агента</span>;
        }
    }

    render() {
        return (
            <div className={classNames('popup-header', this.getBackgroundColorClassName() ? this.getBackgroundColorClassName() : '')}>
                <ShturmanSvg className='popup-header__shturmanSvg' ></ShturmanSvg>
                <FollowSvg className='popup-header__followSvg'></FollowSvg>
                <div className='popup-header__text-container'>
                    <div className='popup-header__text'>Привет, я Штурман,<br />твой электронный помощник.</div>
                    <div className='popup-header__text-with-lines'>
                        <div className='popup-header__white-line'></div>
                        <div className='popup-header__text'>{this.renderHeaderSubText()}</div>
                        <div className='popup-header__white-line'></div>
                    </div>
                </div>
            </div>
        );
    }
}