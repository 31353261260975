import React from 'react';
import './popup-inner-view.scss';
import { Button } from '../../button/Button';
import { bool, func, string } from 'prop-types';

export class SucsessInnerView extends React.Component {
    
    static propTypes = {
        onClick: func
    }
   
    render() {
        const { onClick } = this.props;
        return (
            <div className="popup-inner-view">
                <div className="popup-inner-view__item">
                    <Button onClick={onClick}>Спасибо, ожидаю</Button>
                </div>
            </div>
        );
    }
}