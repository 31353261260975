import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Cookies from 'js-cookie';

import api from 'api';
import routes from './routes';
import AppContext from './store/context';
import {ReactComponent as SVGSprite} from './svg/sprite.svg'

import './App.scss';
import {isLoggedIn, removeToken} from "./modules/auth";

class App extends Component {
  setData = ({
               user,
               token,
             }) => {
    this.setState({
      user,
      token,
      isWaiting: false,
    });
  };

  setHistoryCards = (historyCards) => {
    this.setState({
      historyCards,
    });
  };

  state = {
    user: null,
    token: null,
    setData: this.setData,
    historyCards: null,
    setHistoryCards: this.setHistoryCards,
    isWaiting: true,
  };

  initialData = async () => {
    if (isLoggedIn()) {
      try {
        const response = await api('/profile');

        if (response.status === 200) {
          const user = await response.json();

          this.setData({user});
        } else {
          removeToken();
          this.setState({
            isWaiting: false,
          });
        }
      } catch (e) {
        this.setState({
          isWaiting: false,
        });
      }
    } else {
      this.setState({
        isWaiting: false,
      });
    }
  };

  componentDidMount() {
    this.initialData();
  }

  render() {
    return (
      <>
        <AppContext.Provider value={this.state}>
          <Router>
            {
              routes.map((route) => (
                <Route
                  key={`route-${route.path}`}
                  exact
                  path={route.path}
                  component={route.component}
                />
              ))
            }
          </Router>
        </AppContext.Provider>
        <SVGSprite/>
      </>
    )
  };
}

export default App;
