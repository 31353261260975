import React from 'react';
import './popup-inner-view.scss';
import { Text, TextSize, TextFont, TextAlign } from '../../text/Text';
import { Button } from '../../button/Button';
import { bool, func, string } from 'prop-types';

export class ErrorInnerView extends React.Component {
    static propTypes = {
        errorCode: Number,
        error: String,
        onClick: func
    }

    getErrorText() {
        const {errorCode, error} = this.props;
        switch (errorCode) {
            case 1102:
            case 1103:
            case 1204:
                return 'Наш сервис создан специально для владельцев грузовиков!';

            case 1201:
            case 1202:
            case 1203:
            case 1301:
                return 'Данные для расчета вероятности ДТП по вашему грузовику не доступны.';
            
            default:
                return error;
        }
    }

    render() {
        const {onClick} = this.props;
        return (
            <div className="popup-inner-view">
                <div className="popup-inner-view__item">
                    <Text size={TextSize.S_16} font={TextFont.UBUNTU} textAlign={TextAlign.CENTER}>{this.getErrorText()}</Text>
                </div>
                <div className="popup-inner-view__item">
                    <Button onClick={onClick}>Ввести другой номер</Button>
                </div>
            </div>
        );
    }
}