const YandexShareSettings = {
	content: {
		url: 'https://accident.mustins.ru/',
	},

	theme: {
		services: 'whatsapp,telegram,facebook,odnoklassniki,viber,vkontakte',
		direction: 'vertical',
	},
};

export default YandexShareSettings;
