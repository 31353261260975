import React, {PureComponent} from 'react';
import {Text, TextColor, TextFont, TextSize} from "../../../text/Text";
import {PhoneNumber} from "../../../phone-number/PhoneNumber";
import cn from "classnames";
import {Button} from "../../../button/Button";
import {initOtp} from "../../../main-panel/MainPanelModel";

class LoginPhone extends PureComponent {
	state = {
		phoneNumber: '',
		errorCode: null,
	};

	onNumberChange = (phoneNumber) => {
		this.setState({
			phoneNumber: phoneNumber,
		});
	};

	onValidation = async () => {
		const { phoneNumber } = this.state;

		if (!this.isDataValid()) {
			return;
		}

		const response = await initOtp('+7' + phoneNumber);

		if (response.status === 200) {
			const data = await response.json();

			if (data) {
				if (data.otpState) {
					this.props.onWaitCode(data.otpState, phoneNumber);
				}
				else {
					this.setState({
						errorCode: data.errorCode,
					});
				}
			}
		} else {
			this.setState({
				errorCode: 1,
			});
		}
	};

	isDataValid = () => {
		const { phoneNumber } = this.state;

		return phoneNumber.length === 10;
	};

	getError = () => {
		const {errorCode} = this.state;
		switch (errorCode) {
			case (1001):
				return 'Несуществующий телефонный номер';
			case (1002):
				return 'Неправильный телефонный номер';
			default:
				return 'Что то пошло не так, попробуйте еще раз';
		}
	};

	render() {
		const {
			phoneNumber,
			errorCode,
		} = this.state;

		return (
			<div className="popup-inner-view__item">
				<div className="popup-inner-view__title">
					<Text
						color={TextColor.GRAY}
						size={TextSize.S_14}
						uppercase={true}
						className="popup-inner-view__digit"
					>01</Text>
					<Text
						color={TextColor.ABSOLUTE_BLACK}
						size={TextSize.S_14}
						uppercase={true}
					>Введи свой телефон</Text>
				</div>

				<PhoneNumber
					onNumberChange={this.onNumberChange}
					onEnter={this.onValidation}
					number={phoneNumber}
				/>

				{
					errorCode
					? <div className="popup-inner-view__item">
							<Text color={TextColor.RED} size={TextSize.S_14} font={TextFont.UBUNTU}>{this.getError()}</Text>
						</div>
					: null
				}

				<div className="popup-inner-view__item tc">
					<a href="#" className={'button-link'} onClick={(e) => {
						e.preventDefault()
						this.props.onClose()
						document.getElementById('registationBtn').click()
					}}>
						Я новый пользователь
					</a>
				</div>

				<div className={cn(
						'popup-inner-view__item',
						!this.isDataValid() ? 'popup__disabled' : ''
				)}>
					<Button onClick={ this.onValidation }>Авторизоваться</Button>
				</div>
			</div>
		)
	}
}

export default LoginPhone;