import React from 'react';
import './phone-number.scss';
import {func, string} from 'prop-types';
import classNames from 'classnames';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';

export class PhoneNumber extends React.Component {

  static propTypes = {
    onNumberChange: func,
    onEnter: func,
    number: string
  };

  static defaultProps = {
    onNumberChange() {
    },
    onEnter() {
    }
  };

  state = {
    focus: false
  };

  numberInputRef = React.createRef();
  numberHiddenInputRef = React.createRef();

  handleNumberChange = (e) => {
    const {onNumberChange} = this.props;
    const value = e.target.value || '';
    const numbersArray = value.match(/\d/g) || [];
    let number = numbersArray.join('') || '';
    if (number[0] === '7' || number[0] === '8') {
      number = number.slice(1);
    }
    onNumberChange(number.slice(0, 10));
  };

  handleNumberClick = () => {
    this.numberInputRef.current.focus();
  };

  handleNumberKeyDown = (e) => {
    if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 46 && e.keyCode !== 8 && e.key !== 'Enter') {
      e.preventDefault();
    }
  };


  componentDidUpdate = () => {
    const {number} = this.props;
    if (!isEmpty(number)) {
      let letterSpacing;
      if (size(number) == 3) {
        letterSpacing = 6;
      } else if (size(number) == 6) {
        letterSpacing = 5.8;
      } else if (size(number) == 8) {
        letterSpacing = 5.6;
      }

      this.numberInputRef.current.style.letterSpacing = `${letterSpacing}px`;
    }
  };

  handleKeyPress = (e) => {
    const {onEnter} = this.props;
    if (e.key === 'Enter') {
      onEnter();
    }
  };

  renderChar(number, emptyChar) {
    return (<div className={classNames('phone-number__phone-character', number ? 'phone-number__active-character' : '')}>
      {number ? number : emptyChar || '*'}
    </div>);
  }

  renderHolder(number, holder) {
    return (<div className={classNames('phone-number__phone-character', number ? 'phone-number__active-character' : '')}>
      {holder}
    </div>);
  }

  handleFocus = () => {
    this.setState({focus: true});
  };

  handleBlur = () => {
    this.setState({focus: false});

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  render() {
    const {number} = this.props;
    const {focus} = this.state;
    return (
      <div className={'phone-number'}>
        <input className="phone-number__number-input"
               ref={this.numberInputRef}
               inputMode="tel"
               name="phone"
               onChange={this.handleNumberChange}
               onKeyPress={this.handleKeyPress}
               onFocus={this.handleFocus}
               onBlur={this.handleBlur}
               placeholder={focus ? '' : '+7(***)***-**-**'}
               onKeyDown={this.handleNumberKeyDown}
               value={number}/>

        <div className={classNames('phone-number__number', (focus || size(number))? 'phone-number__number_focused' : '')} onClick={this.handleNumberClick}>
          <div className="phone-number__digits-row">
            {this.renderHolder(number[0], '+')}
            {this.renderHolder(number[0], '7')}
            {this.renderHolder(number[0], '(')}

            {this.renderChar(number[0])}
            {this.renderChar(number[1])}
            {this.renderChar(number[2])}

            {this.renderHolder(number[2], ')')}

            {this.renderChar(number[3])}
            {this.renderChar(number[4])}
            {this.renderChar(number[5])}

            {this.renderHolder(number[5], '-')}

            {this.renderChar(number[6])}
            {this.renderChar(number[7])}

            {this.renderHolder(number[7], '-')}

            {this.renderChar(number[8])}
            {this.renderChar(number[9])}
          </div>
          <span className="phone-number__number-hidden" ref={this.numberHiddenInputRef}>
            {number}
          </span>
        </div>
      </div>
    );
  }
}
