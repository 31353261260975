import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {AUTH_TOKEN_COOKIE, AUTH_TOKEN_EXPIRATION_TIME, USER_COOKIE} from "../constants";
import {getDomainForCookies} from "../utils/getDomainForCookies";

export const getToken = () => {
    return Cookies.get(AUTH_TOKEN_COOKIE)
}

export const getUser = () => {
    const userCookie = Cookies.get(USER_COOKIE)

    return userCookie && JSON.parse(userCookie)
}

export const removeUser = () => {
    Cookies.remove(USER_COOKIE)
}

export const isLoggedIn = () => {
    removeTokenIfExpired()

    return !!getToken()
}

export const isTokenExpired = () => {
    const token = getToken();
    if (!token) {
        return false
    }
    try {
        const decodedToken = jwt_decode(getToken());
        const currentTime = Math.floor(Date.now() / 1000)
        if (decodedToken.exp - currentTime < AUTH_TOKEN_EXPIRATION_TIME) {
            return true
        }
    } catch (error) {
        console.log('invalid token format', error);
        return true;
    }


}

export const removeToken = () => {
    Cookies.remove(AUTH_TOKEN_COOKIE, {
        domain: getDomainForCookies()
    })
}

export const saveToken = token => {
    Cookies.set(AUTH_TOKEN_COOKIE, token, {
        expires: 31,
        domain: getDomainForCookies()
    });
}

export const removeTokenIfExpired = () => {
    if (isTokenExpired()) {
        removeToken()
    }
}
