import React from 'react';
import cn from 'classnames';
import { oneOf, node } from 'prop-types';

import './flat-block.scss';

const FlatBlock = ({
  blockType,
  children,
}) => {
  return (
    <div className={cn(
      'flat-block',
      blockType ? `flat-block_type_${ blockType }` : null,
    )}>{ children }</div>
  )
};

FlatBlock.propTypes = {
  blockType: oneOf(['center']),
  children: node,
};

export default FlatBlock;