import React from 'react';
import './popup.scss';
import {func} from 'prop-types';
import { ReactComponent as CloseSvg } from '../../svg/close.svg';
import { PopupHeader } from './popupHeader/PopupHeader';
import { ErrorInnerView } from './innerViews/ErrorInnerView';
import { SucsessInnerView } from './innerViews/SucsessInnerView';
import { SmsCodeInnerView } from './innerViews/SmsCodeInnerView';
import { InitialInnerView } from './innerViews/InitialInnerView';

export class Popup extends React.Component {
    static propTypes = {
        onErrorHide: func
    };

    state = {
        visible: false,
        number: '',
        smsCode: '',
        name: '',
        errorCode: '',
        error: '',
        phoneNumber: '',
        predictionId: '',
        otpState: null,
        countdown: 1,
        innerView: 'initial'
    }

    hide = () => {
        this.setState({ visible: false });
    }

    show = (innerView, predictionId) => {
        this.setState({
            visible: true,
            innerView: innerView,
            predictionId: predictionId,
            errorCode: '',
            error: ''
        });
    }

    showError = (errorCode, error) => {
        this.setState({
            visible: true,
            innerView: 'error',
            errorCode: errorCode,
            error: error
        });
    }

    setInitialInnerView = () => {
        this.setState({
            visible: true,
            innerView: 'initial',
            errorCode: null,
            error: null
        });
    }
    setSmsCodeInnerView = () => {
        this.setState({
            visible: true,
            innerView: 'smsCode',
            errorCode: null,
            error: null
        });
    }

    setSucsessInnerView = () => {
        this.setState({
            visible: true,
            innerView: 'sucsess',
            errorCode: null,
            error: null
        });
    }

    onNumberChange = (number) => {
        this.setState({ number: number });
    }

    onOtpStateChange = (otpState, phoneNumber, name) => {
        name = name || this.state.name;
        this.setState({
            otpState: otpState,
            phoneNumber: phoneNumber,
            name: name
        });
        this.setSmsCodeInnerView();
    }

    handleNameChange = (e) => {
        this.setState({ name: e.target.value });
    }

    handleErrorClick = () => {
        this.hide();
        this.props.onErrorHide();
    };

    renderInnerView() {
        const { innerView, errorCode, error, otpState, phoneNumber, predictionId, name } = this.state;
        switch (innerView) {
            case 'initial':
                return <InitialInnerView onClick={this.onOtpStateChange} />;
            case 'smsCode':
                return <SmsCodeInnerView
                    onClick={this.setSucsessInnerView}
                    otpState={otpState}
                    phoneNumber={phoneNumber}
                    onUpdateSmsCode={this.onOtpStateChange}
                    predictionId={predictionId}
                    onSuccess={this.setSucsessInnerView}
                    name={name}
                />;
            case 'sucsess':
                return <SucsessInnerView onClick={this.hide} />;
            case 'error':
                return <ErrorInnerView errorCode={errorCode} error={error} onClick={this.handleErrorClick} />;

            default:
                return <InitialInnerView onClick={this.setSmsCodeInnerView} />;
        }
    }

    render() {
        const { visible, innerView, errorCode, error, phoneNumber } = this.state;
        return (
            <div className={visible ? 'popup__container' : 'popup__container hided'}>
                <div className='popup'>
                    <CloseSvg className='popup__closeSvg' onClick={this.hide}></CloseSvg>
                    <PopupHeader
                        innerView={innerView}
                        errorCode={errorCode}
                        phoneNumber={phoneNumber}
                    ></PopupHeader>
                    {this.renderInnerView()}
                </div>
            </div>
        );
    }
}