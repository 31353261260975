import React, {Component} from 'react';

import AppContext from '../../store/context';
import { TitleBlock } from '../../components/title-block/TitleBlock';
import { MainPanel } from '../../components/main-panel/MainPanel';
import { Carousel } from '../../components/carousel/Carousel';
import Layout from '../../components/layout';
import api from "../../api";
import YaShare from 'components/yandex-share';
import {isLoggedIn} from "../../modules/auth";

export default class MainPage extends Component {
  checkUserEmail = (context) => {
    if (!context.user) {
      return null;
    }

    return context.user.email;
  };

  refreshHistory = (context) => () => {
    if (isLoggedIn()) {
      this.getNewHistoryCards(context);
    }
  };

  getNewHistoryCards = async(context) => {
    const response = await api('/profile/history');

    if (response.status === 200) {
      const historyCards = await response.json();

      context.setHistoryCards(historyCards.history);
    }
  };

  render() {
    let repeatPlate = null;
    let repeatRegion = null;

    if (this.props.location.state) {
      repeatPlate = this.props.location.state.repeatPlate
        ? this.props.location.state.repeatPlate
        : null;
      repeatRegion = this.props.location.state.repeatRegion
        ? this.props.location.state.repeatRegion
        : null;
    }

    return (
      <Layout>
        <div className="app">
          <div className="app__title-block">
            <TitleBlock/>
          </div>
          <div className="app__main-panel">
            <AppContext.Consumer>
              {
                (context) => (
                  <MainPanel
                    number={ repeatPlate }
                    region={ repeatRegion }
                    email={ this.checkUserEmail(context) }
                    refreshHistory={ this.refreshHistory(context) }
                  />
                )
              }
            </AppContext.Consumer>
          </div>
          <div className="app__carousel">
            <Carousel/>
          </div>
        </div>
        <YaShare />
      </Layout>
    )
  }
}
