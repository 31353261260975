import React, { Component } from 'react';
import AppContext from 'store/context';

import MustPopup from 'components/mustPopup';
import { SmsCode } from 'components/sms-code/SmsCode';
import { Text, TextColor, TextFont, TextSize } from 'components/text/Text';
import { Countdown } from 'components/countdown/Countdown';
import { Button } from 'components/button/Button';
import {apply, initOtp, login} from "../../MainPanelModel";
import errorCodes from "../../../../utils/errorCodes";
import api from "../../../../api";

class MainPanelOSAGOAuth extends Component {
	state = {
		otpState: '',
		countdown: 1,
		error: null,
    smsCode: '',
		isSuccess: false,
  };

	componentDidMount() {
		setTimeout(() => {

			this.updateSmsCode();
		}, 2000);
	}

	updateSmsCode = async () => {
    const {
    	phone,
    } = this.context.user;

    this.setState({
	    smsCode: '',
    });

    const response = await initOtp(phone);
    const data = await response.json();

    if (data) {
      if (data.otpState) {
        this.setState({
          otpState: data.otpState,
        });
      } else {
        const error = errorCodes(data.errorCode);

				this.setState({
					error,
				});
      }
      this.setState({countdown: 1});
    }
  };

	sendData = async (smsCode) => {
    const {
    	otpState,
    } = this.state;
    const {
    	firstName,
    	phone,
    } = this.context.user;

    const response = await api('/auth/login', 'POST', {
			phone,
	    otpState,
	    otp: smsCode,
    });

    if (response.status === 400) {
      const data = await response.json();

      if (data && data.errorCode) {
        const error = errorCodes(data.errorCode);

				this.setState({
					error,
				});
      }
    } else {
	    const { predictionId } = this.props;
	    const response = await api('/prediction/apply', 'POST', {
	      predictionId,
	      name: firstName,
	    });

	    if (response.status === 200) {
	      this.setState({
	        isSuccess: true,
	      });
	    }
    }
  };

	onSmsCodeChange = (smsCode) => {
		this.setState({
			smsCode,
		});

		if (smsCode.length === 4) {
			this.sendData(smsCode);
		}
	};

	onCountdownEnd = () => {
		this.setState({
			countdown: 0,
		});
	};

	handleClose = () => {
		this.props.onAcceptClick();
	};

	render() {
		if (!this.context.user) {
			return null;
		}

		const { phone } = this.context.user;
		const {
			countdown,
			error,
			smsCode,
			isSuccess,
		} = this.state;
		const headerText = phone
			? <span>мы отправили СМС с кодом<br/>на номер +7({phone.substr(2, 3)})***-**-{phone.substr(-2)}<br/>чтобы назначить тебе агента</span>
			: '';
		const successText = <span>я назначил тебе страхового <br />агента, он позвонит<br />в ближайшее время</span>;

		return (
			<MustPopup
				header={ isSuccess ? successText : headerText }
				onClose={ this.handleClose }
			>
				{
					this.state.isSuccess
						? <div className="popup-inner-view__item">
								<Button onClick={ this.handleClose }>Спасибо, ожидаю</Button>
							</div>

						: <div className="popup-inner-view__item">
								<div className="popup-inner-view__title">
									<Text
										color={TextColor.ABSOLUTE_BLACK}
										size={TextSize.S_14}
										uppercase={true}
									>Код из СМС</Text>
								</div>

								<SmsCode
									onSmsCodeChange={ this.onSmsCodeChange }
									smsCode={ smsCode }
								/>

								{
									error
										? <div className="popup-inner-view__item">
											<Text color={TextColor.RED}>{ error }</Text>
										</div>
										: null
								}

								{
									countdown !== 0
										? <div className="popup-inner-view__item">
											<Text
												size={TextSize.S_12}
												font={TextFont.UBUNTU}
												className="popup-inner-view__countdown-text"
											>Запросить код повторном можно через <Countdown onTimeOver={this.onCountdownEnd}/> сек </Text>
										</div>
										: null
								}

								{
									countdown === 0
										? <div className="popup-inner-view__item">
												<Button
													onClick={ this.updateSmsCode }
													className='popup-inner-view__no-border-button'
												>Запросить новый код</Button>
											</div>
										: null
								}
							</div>
				}
			</MustPopup>
		)
	}
}

MainPanelOSAGOAuth.contextType = AppContext;

export default MainPanelOSAGOAuth;