import React from 'react';
import './speedo.scss';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';

export class Speedo extends React.Component {
    state = {
        current: 0,
    }

    static propTypes = {
        start: Number,
        duration: Number,
        defaultStepCount: Number,
        end: Number
    };

    static defaultProps = {
        start: 0,
        defaultStepCount: 100,
        duration: 2500
    };

    getStepCount = () => {
        const { start, end, defaultStepCount } = this.props;
        const step = parseFloat(((end - start) / defaultStepCount).toFixed(3));
        if (step < 0.01) {
            return end / 0.01;
        }
        return defaultStepCount;
    };

    componentDidMount() {
        const { start, duration, end } = this.props;
        let stepCount = this.getStepCount();
        const interval = duration / stepCount;
        const step = parseFloat(((end - start) / stepCount).toFixed(3));
        this.myInterval = setInterval(() => {
            const { current } = this.state;
            const newCurrent = parseFloat(parseFloat(current + step).toFixed(2));
            this.setState(({ current }) => ({
                current: newCurrent < end ? newCurrent : end
            }))

            if (end - current <= step) {
                clearInterval(this.myInterval);
            }

        }, interval);
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        const { current } = this.state;
        const { className, children } = this.props;
        return (<span className={classNames('speedo', className ? className : '')}>{Number(current).toFixed(2)}{children}</span>)
    }
}